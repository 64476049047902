import { toastr } from "react-redux-toastr";
import * as actionTypes from "./actiontypes";
import guid from "../../assets/js/guid";
import { objectToArray } from "../../assets/js/helpers";
import { incrementTutorial } from "./tutorials";

export const loadClasses = () => {
  return { type: actionTypes.LOADCLASSES };
};

export const loadClassesComplete = () => {
  return { type: actionTypes.LOADCLASSESCOMPLETE };
};

export const createClass = (newClassName, uid) => {

  return async (dispatch, getState, { getFirestore }) => {

    const currentStep = getState().firestore.data[uid].AppData.localUI.tutorials.step;
    dispatch(loadClasses());
    const firestore = getFirestore();
    const ID = guid();
    const newClass = {
      id: ID,
      name: newClassName
    };

    // Update selected class
    await Promise.all([
      await firestore
        .collection(uid)
        .doc("AppData")
        .collection("classes")
        .doc(newClass.id)
        .set(Object.assign(newClass)),
      await firestore
        .collection(uid)
        .doc("AppData")
        .collection("localUI")
        .doc("activeClass")
        .set(newClass),
    ]).then(response => {
      dispatch(loadClassesComplete());
      if (currentStep === 1) {
        dispatch(incrementTutorial(uid, currentStep));
      }

      toastr.success(
        "Success!",
        `${newClass.name} Class was created.`
      );
    })
  };
};

export const selectClass = selectedClassName => {
  return async (dispatch, getState, { getFirestore }) => {
    // Get state data
    const uid = getState().firebase.auth.uid

    const stateClasses = objectToArray(getState().firestore.data[uid].AppData.classes);
    let classes = null

    // Clean up classes do to react-redux-firestore bug
    if (stateClasses) {
      classes = stateClasses.filter((singleClass) => {
        return singleClass !== undefined
      })
    }
    // Get the object of the selected class from the class array
    const newSelectedClass = classes.filter(singleClass => {
      return singleClass.name === selectedClassName;
    });

    const firestore = getFirestore()
    try {
      await firestore
        .collection(uid)
        .doc("AppData")
        .collection("localUI")
        .doc("activeClass")
        .set({
          id: newSelectedClass[0].id,
          name: newSelectedClass[0].name
        })
        .then(response => {
        });
    } catch (error) { }
  };
};


export const saveClass = (newClassName) => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch(loadClasses());
    const firestore = getFirestore();
    // Get UID and active class ID to make firestore update
    const uid = getState().firebase.auth.uid;
    const activeClassID = getState().firestore.data[uid].AppData.localUI
      .activeClass.id;
    // Create new class and active class objects
    const newClass = {
      id: activeClassID,
      name: newClassName
    };


    await Promise.all([
      await firestore
        .collection(uid)
        .doc("AppData")
        .collection("classes")
        .doc(activeClassID)
        .set(newClass),
      await firestore
        .collection(uid)
        .doc("AppData")
        .collection("localUI")
        .doc("activeClass")
        .set(newClass),
    ]).then(response => {
      dispatch(loadClassesComplete());
      toastr.success(
        "Success!",
        `Class name was changed to ${newClass.name}`
      );
    })
  };
}

export const deleteClass = (activeClass, uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch(loadClasses());
    const firestore = getFirestore();

    // Delete all associated homework
    const currentHomework = objectToArray(getState().firestore.data[uid].AppData.homework);
    if (currentHomework) {
      let homeworks = JSON.parse(JSON.stringify(currentHomework));
      const filteredHomework = homeworks.filter((homework) => {
        return homework.class === activeClass.id
      })
      for (let i = 0; i < filteredHomework.length; i++) {
        await
          firestore
            .collection(uid)
            .doc("AppData")
            .collection("homework")
            .doc(filteredHomework[i].id)
            .delete()
      }
    }

    // Delete all associated students
    const currentStudents = objectToArray(getState().firestore.data[uid].AppData.students);

    if (currentStudents) {
      let students = JSON.parse(JSON.stringify(currentStudents));
      const filteredStudent = students.filter((student) => {
        return student.class === activeClass.id
      })
      for (let i = 0; i < filteredStudent.length; i++) {
        await
          firestore
            .collection(uid)
            .doc("AppData")
            .collection("students")
            .doc(filteredStudent[i].id)
            .delete();
      }
    }

    // Delete Class
    await Promise.all([
      await firestore
        .collection(uid)
        .doc("AppData")
        .collection("classes")
        .doc(activeClass.id)
        .delete(),
    ]).then(response => {
      toastr.warning(
        "Success!",
        `${activeClass.name} Class was deleted`
      );
      dispatch(loadClassesComplete());
    })

    //Forces a get request from firebase to update classes, this is due to a bug in react-redux firebase
    await firestore.get(`${uid}/AppData/classes`).then(response => {
    })


    // Clean up classes do to react-redux-firestore bug
    const stateClasses = objectToArray(getState().firestore.data[uid].AppData.classes);
    let classes = null
    if (stateClasses) {
      classes = stateClasses.filter((singleClass) => {
        return singleClass !== undefined
      })
    }
    if (classes) {
      const newActiveClass = {
        id: classes[0].id,
        name: classes[0].name
      }
      await firestore
        .collection(uid)
        .doc("AppData")
        .collection("localUI")
        .doc("activeClass")
        .set(newActiveClass).then(response => {
        })
    } else {
      await firestore
        .collection(uid)
        .doc("AppData")
        .collection("localUI")
        .doc("activeClass")
        .delete().then(response => {
        })
    }
  }
}