import React, { Component } from "react";
import Aux from "./../../hoc/Aux";
import guid from "../../../assets/js/guid";
import StudentInput from "./studentInput/studentInput";
import "./../modal.css";
import "./createStudentModal.css";

export class CreateStudentModal extends Component {
  state = {
    newStudents: [{ fname: "", lname: "", lnameError: "", fnameError: "" }]
  };

  formError = true;
  tabControl = "-1";
  disabled = " disabled";

  render() {
    const enterPressed = event => {
      var code = event.keyCode || event.which;
      if (code === 13) {
        if (!this.formError) {
          this.props.closeModal();
          this.props.createStudents(this.state.newStudents);
        }
      }
    };

    // REMOVE STUDENT ROW
    const deleteStudentRow = id => {
      const student = JSON.parse(JSON.stringify(this.state.newStudents));
      student.splice(id, 1);
      this.setState({
        newStudents: student
      });
      // Enable row if there is no errors
      if (!this.formError) {
        this.tabControl = "";
        this.disabled = "";
      }
      // Disable row if you find an empty row
      for (let i = 0; i < student.length; i++) {
        if (student[i].fname.length === 0 && student[i].lname.length === 0) {
          this.tabControl = "-1";
          this.disabled = " disabled";
          break;
        }
      }
      // Checks forms for any errors, sets form state accordingly
      for (let i = 0; student.length > i; i++) {
        // Does any row have an error?
        if (student[i].fnameError.length === 0 && student[i].lnameError.length === 0) {
          // if no errors, set form to be in valid state
          this.formError = false
        } else {
          // if errors, set form to be in error state
          this.formError = true;
        }
      }

    };

    //STUDENT NAME CHANGE
    const newStudentChangeHandler = event => {
      let index = event.target.getAttribute("data-index");
      const activeClass = this.props.activeClass.key;
      const name = event.target.getAttribute("data-name");
      const input = event.target.value;
      const newStudents = [...this.state.newStudents];
      let fname = "";
      let lname = "";
      let fnameError = this.state.newStudents[index].fnameError;
      let lnameError = this.state.newStudents[index].lnameError;
      if (name === "fname") {
        fname = input;
        lname = this.state.newStudents[index].lname;
      } else if (name === "lname") {
        fname = this.state.newStudents[index].fname;
        lname = input;
      }
      const updateStudent = {
        key: guid(),
        class: activeClass,
        lname: lname,
        fname: fname,
        lnameError: lnameError,
        fnameError: fnameError
      };
      newStudents[index] = updateStudent;
      this.setState({
        newStudents: newStudents
      });
      validation(event, newStudents);
    };

    // ADD ANOTHER STUDENT ROW
    const addAnotherStudentHandler = () => {
      const newStudents = [...this.state.newStudents];
      const lastIndex = newStudents.length - 1;
      if (
        newStudents[lastIndex].lname !== "" ||
        (newStudents[lastIndex].fname !== "" && !this.formError)
      ) {
        newStudents.push({
          fname: "",
          lname: "",
          fnameError: "",
          lnameError: ""
        });
        this.setState({
          newStudents: newStudents
        });
      }
      this.tabControl = "-1";
      this.disabled = " disabled";
    };

    // FORM VALIDATION
    const validation = (event, newStudents) => {
      let index = event.target.getAttribute("data-index");
      let name = event.target.getAttribute("data-name");
      let nameError = "";
      if (name === "fname") {
        nameError = "fnameError";
      }
      if (name === "lname") {
        nameError = "lnameError";
      }
      newStudents[index][nameError] = "";
      const studentNameRegex = /^[a-z\s/']+$/i;

      if (!studentNameRegex.test(newStudents[index][name])) {
        newStudents[index][nameError] = "Name Can only contain letters";
      }
      if (newStudents[index][name].length === 0) {
        newStudents[index][nameError] = "Name is required";
      }
      // Check to see if any first names have errors
      for (let i = 0; i < this.state.newStudents.length; i++) {
        if (newStudents[i].fnameError.length > 0) {
          this.formError = true;
          this.tabControl = "-1";
          this.disabled = " disabled";
          break;
        } else if (newStudents[i].lnameError.length > 0) {
          this.formError = true;
          this.tabControl = "-1";
          this.disabled = " disabled";
          break;
        } else {
          this.formError = false;
          this.tabControl = "";
          this.disabled = "";
        }

        // Check to see if any last names have errors
        for (let i = 0; i < this.state.newStudents.length; i++) {
          if (newStudents[i].lnameError.length > 0) {
            this.formError = true;
            break;
          } else {
            this.formError = false;
          }
        }
      }

  



      

    };

    let body = this.state.newStudents.map((newStudent, index) => {
      return (
        <StudentInput
          enter={enterPressed}
          key={index}
          fname={newStudent.fname}
          lname={newStudent.lname}
          fnameError={newStudent.fnameError}
          lnameError={newStudent.lnameError}
          change={newStudentChangeHandler}
          index={index}
          students={this.state.newStudents}
          deleteStudent={event => deleteStudentRow(event.target.id)}
        />
      );
    });
    return (
      <Aux>
        <div className="lightbox" />
        <div
          className="modal show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "inline-block" }}
        >
          <div
            className="modal-dialog modal-lg fadeInDown animated"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Add Students to {this.props.activeClass.name}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-5 col-md-6">
                    <label htmlFor="firstName">First Name</label>
                  </div>
                  <div className="col-5">
                    <label htmlFor="lastName">Last Name</label>
                  </div>
                  <div className="col-2" />
                </div>
                {body}
                <div className="form-group add-next-student">
                  <div className="row">
                    <div className="col-5">
                      <input
                        type="text"
                        className={"form-control " + this.disabled}
                        placeholder="First Name"
                        tabIndex={this.tabControl}
                        onFocus={addAnotherStudentHandler}
                      />
                    </div>
                    <div className="col-5">
                      <input
                        type="text"
                        className={"form-control " + this.disabled}
                        placeholder="Last Name"
                        tabIndex={this.tabControl}
                        onClick={addAnotherStudentHandler}
                      />
                    </div>
                    <div className="col-2" />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col text-right">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={this.props.closeModal}
                    >
                      Cancel
                    </button>
                    {this.formError ? (
                      <button
                        type="button"
                        className="btn btn-primary inactive"
                        id="callToAction"
                      >
                        Add Students
                      </button>
                    ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          id="callToAction"
                          onClick={() => {
                            this.props.closeModal();
                            this.props.createStudents(this.state.newStudents);
                          }}
                        >
                          Add Students
                      </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

export default CreateStudentModal;
