import * as actionTypes from "./actiontypes";
import { toastr } from "react-redux-toastr";




export const registerUser = (email, password) => {
  return async (dispatch, getState, { getFirebase }) => {
    dispatch(authStart());
    const firebase = getFirebase();
    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(response => {
          // dispatch(initializeCollection(response.user.uid));
          dispatch(authSuccess(response));
        });
    } catch (error) {
      dispatch(regFail(error.message));
    }
  };
};

export const authStart = () => {
  return { type: actionTypes.AUTHSTART };
};

export const logout = () => {
  return { type: actionTypes.LOGOUT };
};

export const authErrorClear = () => {
  return { type: actionTypes.AUTHERRORCLEAR };
};

export const authSuccess = data => {
  return { type: actionTypes.AUTHSUCCESS, data: data };
};

export const authFail = err => {
  const error = "You entered a wrong email or password";
  return { type: actionTypes.AUTHFAIL, err: error };
};

export const regFail = err => {
  const error = "Something is wrong! Please contact us at 902-210-1528";
  return { type: actionTypes.REGFAIL, err: error };
};

// export const initializeCollection = uid => {
//   return async (dispatch, getState, { getFirestore }) => {
//     const firestore = getFirestore();

//     try {
//       await firestore.add(uid, {}).then(response => {
//         console.log(uid, null);
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };

export const loginUser = (email, password) => {
  return async (dispatch, getState, { getFirebase, getFireStore }) => {
    dispatch(authStart());
    const firebase = getFirebase();
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(response => {
          dispatch({ type: actionTypes.AUTHSUCCESS, data: response });
        });
    } catch (error) {
      dispatch(authFail(error.message));
    }
  };
};

export const loginUserWithGoogle = () => {

  return async (dispatch, getstate, { getFirebase }) => {
    const firebase = getFirebase();
    var provider = new firebase.auth.GoogleAuthProvider();
    dispatch(authStart());
    try {
      await firebase.auth().signInWithRedirect(provider)
      dispatch({ type: actionTypes.AUTHSUCCESS });
    } catch (error) {
      dispatch(authFail(error.message));
    }
  };
};

export const forgotPassword = (email) => {
  let errorMessage = `We could not send password reset instructions to ${email}`
  return async (dispatch, getstate, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      await firebase.auth().sendPasswordResetEmail(email).then((response) => {
        toastr.success(
          "Reset link sent!",
          `We have sent a reset instructions to ${email}`
        );

      })
    } catch (error) {

      toastr.error(
        "Uh Oh",
        `${errorMessage}`
      );
    }
  };
};


export const setNewPassword = (code, newPassword, history) => {
  return async (dispatch, getstate, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      await firebase.auth().confirmPasswordReset(code, newPassword).then((response) => {
        toastr.success(
          "Password was set",
        );
        history.push('/');
      })
    } catch (error) {
      toastr.error(
        "Uh Oh",
        `we could not change your password`
      );
    }
  };
};

