import React, { Component } from "react";
import Aux from "../hoc/Aux";
import Checker from "../student/checker/checker";
import "./studentCard.css";

export class StudentCard extends Component {
  state = {
    queueDelete: false,
    edit: false,
    newFName: "",
    newLName: "",
    newFNameError: "",
    newLNameError: ""
  };

  render() {
    const queueDelete = () => {
      this.setState({
        queueDelete: !this.state.queueDelete
      });
    };

    const onStudentChangeHandler = event => {
      const type = event.target.getAttribute("id");
      const value = event.target.value;
      this.setState({
        [type]: value
      });
      validation(type, value);
    };

    //Get object of selectedStudent
    const selectedStudent = JSON.parse(
      JSON.stringify(this.props.students)
    ).filter(student => {
      return student.id === this.props.studentID;
    });

    // Get Homework Details Object
    const filteredHomework = [];
    let isHomework = false

    if (this.props.homeworks) {
      isHomework = true;
      const homeworks = JSON.parse(JSON.stringify(this.props.homeworks));
      for (let i = 0; i < selectedStudent[0].homework.length; i++) {
        for (let j = 0; j < homeworks.length; j++) {
          if (homeworks[j].id === selectedStudent[0].homework[i].id) {
            filteredHomework.push(homeworks[j]);
          }
        }
      }
    } else {
      isHomework = false
    }

    const edit = () => {
      this.setState({
        edit: !this.state.edit,
        newFName: selectedStudent[0].fname,
        newLName: selectedStudent[0].lname
      });
    };

    const validation = (type, value) => {
      let errorType = "";
      if (type === "newFName") {
        errorType = "newFNameError";
      } else {
        errorType = "newLNameError";
      }
      this.setState({
        [errorType]: ""
      });

      const studentNameRegex = /^[a-z\s/']+$/i;
      if (!studentNameRegex.test(value)) {
        this.setState({
          [errorType]: "Name Can only contain letters"
        });
      }
      if (value.length === 0) {
        this.setState({
          [errorType]: "Name is required"
        });
      }
    };

    // Combine student homework check with filtered homework
    filteredHomework.map(homework => {
      for (let i = 0; i < selectedStudent[0].homework.length; i++) {
        if (selectedStudent[0].homework[i].id === homework.id) {
          homework.check = selectedStudent[0].homework[i].check;
        }
      }
      return homework;
    });

    let totalHomework = filteredHomework.length;
    let completedHomework = 0;
    let naHomework = 0;

    for (let i = 0; i < filteredHomework.length; i++) {
      if (filteredHomework[i].check === "yes") {
        completedHomework++;
      }
    }
    for (let i = 0; i < filteredHomework.length; i++) {
      if (
        filteredHomework[i].check === "na" ||
        filteredHomework[i].check === ""
      ) {
        naHomework++;
      }
    }

    totalHomework = totalHomework - naHomework;
    completedHomework.toString();
    totalHomework.toString();
    const homework = filteredHomework.map((homework, index) => {
      // Convert homework to an array
      let homeworkCheck = [];
      homeworkCheck.push(homework);
      // Strip homework from array and store in its own array because checker expects an array
      return (
        <tr className="d-flex align-middle student-row" key={homework.id}>
          <td className="col-3 col-lg-3 align-middle name not-mobile">{homework.dateCreated}</td>
          <td className="col-4 col-lg-4 align-middle name truncate">{homework.description}</td>
          <td className="col-8 col-lg-5 text-center align-middle">
            <Checker
              studentID={selectedStudent[0].id}
              homework={homeworkCheck}
              checkHomework={(value, studentID, homeworkID) =>
                this.props.checkHomework(value, studentID, homeworkID)
              }
            />
          </td>
        </tr>
      );
    });

    return (
      <Aux>
        <div className="lightbox" />
        <div
          className="modal show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "inline-block" }}
        >
          <div
            className="modal-dialog modal-lg fadeInDown animated"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                {!this.state.edit ? (
                  <Aux>
                    <h5 className="modal-title truncate">
                      {selectedStudent[0].fname}
                      &nbsp;
                      {selectedStudent[0].lname}
                    </h5>
                  </Aux>
                ) : (

                    <div className="form-group">
                      <div className="row">
                        <div className="col-6">
                          <label htmlFor="newFname">First Name</label>
                          <input
                            type="text"
                            className="form-control animated fadeIn"
                            id="newFName"
                            value={this.state.newFName}
                            placeholder="First name"
                            onChange={onStudentChangeHandler}
                          />
                          <div className="is-invalid">
                            {this.state.newFNameError}
                          </div>
                        </div>
                        <div className="col-6">
                          <label htmlFor="newLname">Last Name</label>
                          <input
                            type="text"
                            className="form-control animated fadeIn"
                            id="newLName"
                            value={this.state.newLName}
                            placeholder="Last name"
                            onChange={onStudentChangeHandler}
                          />
                          <div className="is-invalid">
                            {this.state.newLNameError}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {!this.state.edit ?
                  <div className="btn btn-link animated fadeIn" onClick={edit}>
                    Edit
                  </div> : null}
                {!this.state.edit ? (
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.props.closeModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                ) : null}
              </div>
              {this.state.edit ?
                <Aux>
                  <div className="row text-right actions">
                    <div className="col ">
                      <div
                        className="btn btn-link animated fadeIn"
                        onClick={edit}>
                        Cancel
                    </div>
                      {this.state.newFNameError.length > 0 ||
                        this.state.newLNameError.length > 0 ? (
                          <button
                            type="button"
                            className="btn btn-primary inactive">
                            Save
                    </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              this.props.renameStudent(
                                this.props.studentID,
                                this.state.newFName,
                                this.state.newLName
                              );
                              edit();
                            }}>
                            Save
                    </button>
                        )}
                    </div>
                  </div>
                </Aux>
                : null}

              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="stats">
                      <span className="label">Completed Homework: </span>
                      <span className="total">{completedHomework}</span>
                      <span className="score">/{totalHomework}</span>
                    </div>
                  </div>
                </div>
              </div>
              {isHomework ? (
                <table className="table table-reponsive studentCard table-hover animated fadeIn">
                  <thead>
                    <tr className="d-flex header-row">
                      <th className="col-3 col-lg-3 date-created not-mobile" id="date">
                      </th>
                      <th className="col-4 col-lg-4" id="description">
                        Description
                    </th>
                      <th className="col-8 col-lg-5 animated fadeIn text-center">
                        Homework Checker
                    </th>
                    </tr>
                  </thead>
                  <tbody>{homework}</tbody>
                </table>

              ) :
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <span className="homework-placeholder">There is no homework assigned</span>
                    </div>
                  </div>
                </div>
              }

              <div className="modal-footer">
                <div className="row justify-content-end">
                  <div className="col">
                    {this.state.queueDelete === false ? (
                      <Aux>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={queueDelete}
                        >
                          Delete Student
                        </button>
                      </Aux>
                    ) : (
                        <div className="animated fadeIn">
                          <div className="deleteWarning">Are you sure?</div>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              this.props.deleteStudent(this.props.studentID);
                              this.props.closeModal();
                            }}
                          >
                            Yes
                        </button>
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={queueDelete}
                          >
                            No
                        </button>
                        </div>
                      )}
                    {/* <button type="button" className="btn btn-link">
                      Edit Student
                    </button> */}
                  </div>
                  {!this.state.queueDelete ?
                    <div className="col text-right">
                      {this.state.newFNameError.length > 0 ||
                        this.state.newLNameError.length > 0 ? (
                          <button
                            type="button"
                            className="btn btn-primary inactive"
                            onClick={this.props.closeModal}
                          >
                            Done
                      </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.props.closeModal}
                          >
                            Done
                      </button>
                        )}
                    </div>
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

export default StudentCard;
