import * as actionTypes from "../actions/actiontypes";


const initialState = {
  students: [
  ],
  loadStudents: false
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    // CREATE STUDENTS
    case actionTypes.CREATESTUDENTS:
      // Get new students, current homework, and current students
      const newStudents = action.newStudents;
      newStudents.filter(newStudent => {
        return newStudent.fname !== "" || newStudent.lname !== "";
      });
      const homework = JSON.parse(JSON.stringify(action.homeworks));
      const students = JSON.parse(JSON.stringify(state.students));
      // filter current homework to only active class
      const filteredHomework = homework.filter(homework => {
        return homework.class === action.activeClass.key;
      });
      // Create the homework array to be applied to students
      const homeworkArray = filteredHomework.map(homework => {
        return { key: homework.key, check: "" };
      });
      // Add the homework array to each new student
      newStudents.map(student => {
        return (student["homework"] = homeworkArray);
      });
      newStudents.map(student => {
        return (student.class = action.activeClass.key);
      });
      // Combine new students and existing students
      const newStudentsList = students.concat(newStudents);
      // Set state with students and clear new students array
      return {
        ...state,
        students: newStudentsList
      };

    // ASSIGN HOMEWORK
    case actionTypes.ASSIGNHOMEWORK: {
      // Get affected students
      const affectedstudents = JSON.parse(
        JSON.stringify(state.students)
      ).filter(student => {
        return student.class === action.activeClass.key;
      });
      // Builds homework object and puches it into students homework array
      const newHomework = { key: action.guid, check: "" };
      affectedstudents.map(student => {
        return student.homework.push(newHomework);
      });
      // Get list of unaffected students
      const students = JSON.parse(JSON.stringify(state.students)).filter(
        student => {
          return student.class !== action.activeClass.key;
        }
      );
      // Combine students
      const newStudents = students.concat(affectedstudents);
      // Update State
      return {
        ...state,
        students: newStudents
      };
    }

    //DELETE HOMEWORKS
    case actionTypes.DELETEHOMEWORKS: {
      // Get Students associated with this homework
      const impactedStudents = JSON.parse(
        JSON.stringify(state.students)
      ).filter(student => {
        return student.class === action.activeClass.key;
      });
      // Loop through students
      for (let i = 0; i < impactedStudents.length; i++) {
        //Loop through homework
        for (let j = 0; j < impactedStudents[i].homework.length; j++) {
          // If the homework has a matching key for edited homework then deleted it
          if (impactedStudents[i].homework[j].key === action.homeworkKey) {
            impactedStudents[i].homework.splice(j);
          }
        }
      }
      // Get unimpacted students
      const unimpactedStudents = JSON.parse(
        JSON.stringify(state.students)
      ).filter(student => {
        return student.class !== action.activeClass.key;
      });
      // Combines students
      const newStudents = unimpactedStudents.concat(impactedStudents);
      // Update State
      return {
        ...state,
        students: newStudents
      };
    }

    // CHECK HOMEWORK
    case actionTypes.CHECKHOMEWORK: {
      // Get affect student
      const affectedStudent = JSON.parse(JSON.stringify(state.students)).filter(
        student => {
          return student.key === action.studentKey;
        }
      );
      // Loop through homework until you find the related one, and set check to new value
      for (let i = 0; i < affectedStudent[0].homework.length; i++) {
        if (affectedStudent[0].homework[i].key === action.homeworkKey) {
          affectedStudent[0].homework[i].check = action.value;
        }
      }
      // Get unaffected students
      const unaffectedStudent = JSON.parse(
        JSON.stringify(state.students)
      ).filter(student => {
        return student.key !== action.studentKey;
      });
      // Combine students
      const newStudents = unaffectedStudent.concat(affectedStudent);
      // Update State
      return {
        ...state,
        students: newStudents
      };
    }
    // DELETE STUDENTS IN CLASS
    case actionTypes.DELETESTUDENTSINCLASS: {
      const unaffectedStudents = JSON.parse(
        JSON.stringify(state.students)
      ).filter(student => {
        return student.class !== action.activeClass.key;
      });
      return {
        ...state,
        students: unaffectedStudents
      };
    }
    // DELETE STUDENT
    case actionTypes.DELETESTUDENT: {
      const students = JSON.parse(JSON.stringify(state.students));
      let index = 0;
      for (let i = 0; i < students.length; i++) {
        if (students[i].key === action.studentKey) {
          index = i;
        }
      }
      students.splice(index, 1);
      return {
        ...state,
        students: students
      };
    }
    // RENAME STUDENT
    case actionTypes.RENAMESTUDENT: {
      const students = JSON.parse(JSON.stringify(state.students));
      for (let i = 0; i < students.length; i++) {
        if (students[i].key === action.studentKey) {
          students[i].fname = action.fname;
          students[i].lname = action.lname;
        }
      }

      return {
        ...state,
        students: students
      };
    }

    case actionTypes.LOADSTUDENTS: {
      return {
        ...state,
        loadStudents: true,
      };
    }
    case actionTypes.LOADSTUDENTSCOMPLETE: {
      return {
        ...state,
        loadStudents: false,
      };
    }
    default:
      return state;
  }
};
export default reducer;
