

export const setTutorials = (uid) => {
    return async (dispatch, getState, { getFirestore }) => {
        const tutorials = { step: 1, tutorials: true }
        const firestore = getFirestore();
        await firestore
            .collection(uid)
            .doc("AppData")
            .collection("localUI")
            .doc("tutorials")
            .set(tutorials);
    };
};

export const skipTutorial = (uid, step) => {
    return async (dispatch, getState, { getFirestore }) => {
        const tutorials = { step: step, tutorials: false }
        const firestore = getFirestore();
        await firestore
            .collection(uid)
            .doc("AppData")
            .collection("localUI")
            .doc("tutorials")
            .set(tutorials);
    };
};


export const incrementTutorial = (uid, currentStep) => {
    let newStep = null
    let tutorialsStatus = false
    if (currentStep < 5 && currentStep > 0) {
        newStep = currentStep + 1
        tutorialsStatus = true
    } else {
        newStep = 0
        tutorialsStatus = false
    }


    return async (dispatch, getState, { getFirestore }) => {
        const tutorials = { step: newStep, tutorials: tutorialsStatus }
        const firestore = getFirestore();
        try {
            await firestore
                .collection(uid)
                .doc("AppData")
                .collection("localUI")
                .doc("tutorials")
                .set(tutorials).then(response => {

                })
        } catch (err) {
        }
    };
};