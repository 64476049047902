import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Aux from "../../components/hoc/Aux";
import Logo from "../../assets/images/Logo.png";
import { registerUser } from "../../store/actions/authentication";

import "../Login/login.css";

export class Register extends Component {
  state = {
    email: "",
    emailError: "",
    password: "",
    passwordError: "",
    passwordCapital: false,
    passwordWhitespace: false,
    passwordNumber: false,
    passwordLowercase: false,
    passwordSymbol: false,
    confirmPassword: "",
    confirmPasswordError: "",
    passwordMatch: null,
    passwordWizard: false,
    confirmPasswordWizard: false,
    correctPassword: null
  };
  if() { }

  render() {
    let isPasswordError = null;
    let isPasswordMatchError = null;

    const togglPasswordWizard = () => {
      this.setState({
        passwordWizard: !this.state.passwordWizard
      });
    };

    const togglConfirmPasswordWizard = () => {
      this.setState({
        confirmPasswordWizard: !this.state.confirmPasswordWizard
      });
    };

    const validation = (type, value, blur) => {
      if (type === "email") {
        this.setState({
          emailError: ""
        });
        if (blur) {
          const emailRegex = /\S+@\S+\.\S+/;
          if (!emailRegex.test(value)) {
            this.setState({
              emailError: "Invalid Email"
            });
          }
        }
        if (value.length === 0) {
          this.setState({
            emailError: "Email is required"
          });
        }
      }
      if (type === "password") {
        this.setState({
          passwordError: ""
        });
        if (this.state.confirmPassword !== value) {
          isPasswordMatchError = true;
        } else {
          isPasswordMatchError = false;
        }

        const passwordCapitalLetterRegex = /[A-Z]/;
        const passwordLowercaseLetterRegex = /[a-z]/;
        const passwordNumberRegex = /\d/i;
        const passwordSymbolRegex = /[~!@#$%^&*()_+]/;
        const passwordWhitespaceRegex = /\s/;

        // Is there a white space?
        if (passwordWhitespaceRegex.test(value)) {
          this.setState({
            passwordWhitespace: true,
            passwordError: "Password cannot contain spaces",
            correctPassword: false
          });

          isPasswordError = true;
        } else {
          this.setState({
            passwordWhitespace: false,
            passwordError: ""
          });
        }

        // Is there a password?
        if (value.length === 0) {
          this.setState({
            passwordError: "Password is required",
            correctPassword: false
          });
          isPasswordError = true;
        }

        // Is there a capital?
        if (passwordCapitalLetterRegex.test(value)) {
          this.setState({
            passwordCapital: true
          });
        } else {
          this.setState({
            passwordCapital: false,
            correctPassword: false
          });
          isPasswordError = true;
        }
        // Is there a lowercase?
        if (passwordLowercaseLetterRegex.test(value)) {
          this.setState({
            passwordLowercase: true
          });
        } else {
          this.setState({
            passwordLowercase: false,
            correctPassword: false
          });
          isPasswordError = true;
        }

        //Is there a number?
        if (passwordNumberRegex.test(value)) {
          this.setState({
            passwordNumber: true
          });
        } else {
          this.setState({
            passwordNumber: false,
            correctPassword: false
          });
          isPasswordError = true;
        }
        // Is there a symbol?
        if (passwordSymbolRegex.test(value)) {
          this.setState({
            passwordSymbol: true
          });
        } else {
          this.setState({
            passwordSymbol: false,
            correctPassword: false
          });
          isPasswordError = true;
        }
      }
      if (type === "confirmPassword") {
        this.setState({
          confirmPasswordError: ""
        });
        if (value !== this.state.password) {
          this.setState({
            passwordMatch: false
          });
          isPasswordMatchError = true;
        } else {
          this.setState({
            passwordMatch: true
          });
          isPasswordMatchError = false;
        }
        if (value.length === 0) {
          this.setState({
            confirmPasswordError: "Please confirm your password"
          });
        }
      }

      // Is the password correct?
      if (isPasswordError) {
        this.setState({
          correctPassword: false
        });
      } else if (!isPasswordError && this.state.password.length > 0) {
        this.setState({
          correctPassword: true
        });
      }

      // Do the passwords match?

      if (isPasswordMatchError) {
        this.setState({
          passwordMatch: false
        });
      } else if (isPasswordMatchError === false) {
        this.setState({
          passwordMatch: true
        });
      }
    };
    const emailChangeHandler = event => {
      this.setState({
        email: event.target.value
      });

      validation("email", event.target.value);
    };

    const passwordChangeHandler = event => {
      this.setState({
        password: event.target.value
      });
      validation("password", event.target.value);
    };
    const confirmPasswordChangeHandler = event => {
      this.setState({
        confirmPassword: event.target.value
      });

      validation("confirmPassword", event.target.value);
    };

    let emailIsInvalid = "";
    let passwordIsInvalid = "";
    let confirmPasswordIsInvalid = "";

    if (this.state.emailError.length > 0) {
      emailIsInvalid = " is-invalid";
    }
    if (this.state.passwordError.length > 0) {
      passwordIsInvalid = " is-invalid";
    }
    if (this.state.confirmPasswordError.length > 0) {
      confirmPasswordIsInvalid = " is-invalid";
    }

    let formValid = " inactive";
    if (
      this.state.correctPassword &&
      this.state.passwordMatch &&
      this.state.emailError.length === 0
    ) {
      formValid = "";
    }

    if (this.props.isAuthenticated) {
      return <Redirect to="/app" />;
    }

    const enterPressed = event => {
      var code = event.keyCode || event.which;
      if (code === 13 && formValid !== " inactive") {
        this.props.registerUserHandler(
          this.state.email,
          this.state.password
        )
      }
    };

    return (
      <Aux>
        <Link to="/">
          <nav className="primary-navbar navbar">
            <div className="container-fluid">
              <div className="loginheader">
                <span className="line"> Already have an account? </span>
                <span className="line">&nbsp; Go to Login</span>
              </div>
            </div>
          </nav>
        </Link>
        <div className="background" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6"
              onKeyPress={enterPressed}>
              <div className="card login">
                <div className="row justify-content-center">
                  <div className="col-11 col-sm-8">
                    <img alt="logo" className="logo" src={Logo} />
                    <h4>Register</h4>
                    <hr />

                    {/* EMAIL */}
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        onBlur={event =>
                          validation("email", event.target.value, true)
                        }
                        type="text"
                        id="email"
                        onChange={emailChangeHandler}
                        value={this.state.email}
                        className={"form-control login-input" + emailIsInvalid}
                        placeholder=""
                        autoFocus
                      />
                    </div>
                    {this.state.emailError ? (
                      <div className="is-invalidError">
                        {this.state.emailError}
                      </div>
                    ) : null}

                    {/* PASSWORD */}
                    <div className="form-group password">
                      <label htmlFor="password">Create A Password</label>
                      {this.state.correctPassword &&
                        this.state.passwordError.length === 0 ? (
                          <i className={"material-icons float-right correct"}>
                            check_box
                        </i>
                        ) : null}
                      {this.state.correctPassword === false ? (
                        <i className={"material-icons float-right incorrect"}>
                          close
                        </i>
                      ) : null}
                      <input
                        id="password"
                        type="password"
                        className={
                          "form-control login-input" + passwordIsInvalid
                        }
                        placeholder=""
                        onChange={passwordChangeHandler}
                        onFocus={togglPasswordWizard}
                        onBlur={togglPasswordWizard}
                        value={this.state.password}
                      />
                      {this.state.passwordWizard ? (
                        <div className="wizard">
                          <div className="col">
                            <div className="card">
                              <ul>
                                {/* CONTAINS A CAPITAL */}
                                {this.state.passwordCapital ? (
                                  <li className="complete animated fadeIn">
                                    <i className="material-icons">check_box</i>
                                    <span>Contains a Capital Letter</span>
                                  </li>
                                ) : (
                                    <li className="incomplete animated fadeIn">
                                      <i className="material-icons">
                                        check_box_outline_blank
                                  </i>
                                      <span>Contains a Capital Letter</span>
                                    </li>
                                  )}

                                {/* CONTAINS LOWERCASE */}
                                {this.state.passwordLowercase ? (
                                  <li className="complete animated fadeIn">
                                    <i className="material-icons">check_box</i>
                                    Contains a Lowercase Letter
                                </li>
                                ) : (
                                    <li className="incomplete animated fadeIn">
                                      <i className="material-icons">
                                        check_box_outline_blank
                                  </i>
                                      Contains a Lowercase Letter
                                </li>
                                  )}

                                {/* CONTAINS NUMBER */}
                                {this.state.passwordNumber ? (
                                  <li className="complete animated fadeIn">
                                    <i className="material-icons">check_box</i>Contains
                                    a Number
                                </li>
                                ) : (
                                    <li className="incomplete animated fadeIn">
                                      <i className="material-icons">
                                        check_box_outline_blank
                                  </i>Contains a Number
                                </li>
                                  )}

                                {/* CONTAINS SYMBOL */}
                                {this.state.passwordSymbol ? (
                                  <li className="complete animated fadeIn animated fadeIn">
                                    <i className="material-icons">check_box</i>Contains
                                    a Symbol
                                </li>
                                ) : (
                                    <li className="incomplete animated fadeIn">
                                      <i className="material-icons">
                                        check_box_outline_blank
                                  </i>Contains a Symbol
                                </li>
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {this.state.passwordError ? (
                      <div className="is-invalidError">
                        {this.state.passwordError}
                      </div>
                    ) : null}

                    {/* CONFRIM PASSWORD */}
                    <div className="form-group password">
                      <label htmlFor="confirmpassword">
                        Confirm Your Password
                      </label>
                      {this.state.passwordMatch &&
                        this.state.confirmPasswordError.length === 0 ? (
                          <i className={"material-icons float-right correct"}>
                            check_box
                        </i>
                        ) : null}
                      {this.state.passwordMatch === false ||
                        this.state.confirmPasswordError.length > 0 ? (
                          <i className={"material-icons float-right incorrect"}>
                            close
                        </i>
                        ) : null}
                      <input
                        type="password"
                        id="confirmpassword"
                        className={
                          "form-control login-input" + confirmPasswordIsInvalid
                        }
                        placeholder=""
                        onFocus={togglConfirmPasswordWizard}
                        onBlur={togglConfirmPasswordWizard}
                        onChange={confirmPasswordChangeHandler}
                        value={this.state.confirmPassword}
                      />
                      {this.state.confirmPasswordWizard ? (
                        <div className="wizard">
                          <div className="col">
                            <div className="card">
                              <ul>
                                {/* PASSWORDS MATCH */}
                                {this.state.passwordMatch &&
                                  this.state.confirmPasswordError.length === 0 ? (
                                    <li className="complete animated fadeIn">
                                      <i className="material-icons">check_box</i>
                                      <span>Passwords Match</span>
                                    </li>
                                  ) : (
                                    <li className="incomplete animated fadeIn">
                                      <i className="material-icons">
                                        check_box_outline_blank
                                  </i>
                                      <span>Passwords Match</span>
                                    </li>
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {this.state.confirmPasswordError ? (
                      <div className="is-invalidError">
                        {this.state.confirmPasswordError}
                      </div>
                    ) : null}

                    {/* <div className="password-util row justify-content-end">
                      <div className="col">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox1"
                            value="option1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineCheckbox1"
                          >
                            I would like to opt in to recieve notifications
                            about applicaiton updates
                          </label>
                        </div>
                      </div>
                    </div> */}

                    {this.props.registerError ? (
                      <div className="col text-center login-error animated fadeIn">
                        <div className="error">{this.props.registerError}</div>
                      </div>
                    ) : null}

                    <div
                      className={"btn btn-primary col" + formValid}
                      onClick={() =>
                        this.props.registerUserHandler(
                          this.state.email,
                          this.state.password
                        )
                      }
                    >
                      {this.props.authenticating ? (
                        <div className="btn-content-wrapper animated fadeIn">
                          <div className="loader" />
                          Registering
                        </div>
                      ) : (
                          <div className="animated fadeIn ">Register</div>
                        )}
                    </div>
                    <div
                      className="btn btn-link col"
                      onClick={() => this.props.history.push("/")}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    registerError: state.authentication.registerError,
    authenticating: state.authentication.authenticating,
    isAuthenticated: state.firebase.auth.uid
  };
};

const mapDisptachToProps = dispatch => {
  return {
    registerUserHandler: (email, password) =>
      dispatch(registerUser(email, password))
  };
};

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(Register);
