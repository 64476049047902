import React from "react";
import ReactDOM from "react-dom";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { BrowserRouter } from "react-router-dom";
import thunk from "redux-thunk";

import {
  reactReduxFirebase,
  getFirebase,
  firebaseReducer
} from "react-redux-firebase";
import {
  reduxFirestore,
  getFirestore,
  firestoreReducer
} from "redux-firestore";
import firebase from "./config/firebase";

import { reducer as toastrReducer } from "react-redux-toastr";
import classesReducer from "./store/reducers/classes";
import tutorialsReducer from "./store/reducers/tutorials";
import studentsReducer from "./store/reducers/students";
import homeworksReducer from "./store/reducers/homeworks";
import authenticationReducer from "./store/reducers/authentication";
import { Provider } from "react-redux";

/* css */
import "./App.css";


import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

const rrfConfig = {
  userProfile: "users",
  attachAuthIsReady: true,
  useFirestoreForProfile: true
};

const rootReducer = combineReducers({
  toastr: toastrReducer,
  authentication: authenticationReducer,
  tutorials: tutorialsReducer,
  classes: classesReducer,
  homeworks: homeworksReducer,
  students: studentsReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer
});

export const configureStore = preloadedState => {
  const middlewares = [thunk.withExtraArgument({ getFirebase, getFirestore })];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const storeEnhancer = [middlewareEnhancer];

  const composeEnhancer = composeWithDevTools(
    ...storeEnhancer,
    reactReduxFirebase(firebase, rrfConfig),
    reduxFirestore(firebase)
  );

  const store = createStore(rootReducer, preloadedState, composeEnhancer);

  return store;
};

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
