import React, { Component } from 'react';
import './homework.css';


class Homework extends Component {
  render() {
    let selected = '';


    if (this.props.selectedHomework) {

      if (this.props.homeworkID === this.props.selectedHomework.id) {
        selected = " no-hover";
      }
    }

    return (
      <tr id={this.props.homeworkID} data-homework="true" className={"d-flex" + selected} data-key={this.props.homeworkID}>
        <td id={this.props.homeworkID} data-homework="true" className="col-3 truncate">{this.props.date}</td>
        <td id={this.props.homeworkID} data-homework="true" className="col-5 truncate">{this.props.description}
        </td>
        <td id={this.props.homeworkID} data-homework="true" className="col-4 text-center">
          <button
            type="button"
            className="btn btn-link btn-sm"
            data-homeworkkey={this.props.id}
            onClick={() => {
              this.props.editHomework();
              this.props.provideHomeworkDetails(this.props.description, this.props.homeworkID);

            }}
          >Edit</button>
        </td>
      </tr>
    )
  }
}


export default Homework
