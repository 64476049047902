import React, { Component } from "react";
import Aux from "../../hoc/Aux";

export class CreateHomeworkModal extends Component {
  state = {
    newHomework: "",
    newHomeworkError: ""
  };
  formError = true;

  render() {
    let homeworkNameInvalid = "";
    if (this.state.newHomeworkError !== "") {
      homeworkNameInvalid = " is-invalid";
    }
    const enterPressed = event => {
      var code = event.keyCode || event.which;
      if (code === 13) {
        const err = validation(this.state.newHomework);
        if (!err) {
          this.props.closeModal();
          this.props.createHomework(this.state.newHomework);
        }
      }
    };
    // Update controlled form
    const changeHomeworkHandler = event => {
      this.setState({
        newHomework: event.target.value
      });
      validation(event.target.value);
    };
    //Validate Homework Description
    const validation = newHomework => {
      let homeworkDescription = newHomework;
      if (!homeworkDescription.length > 0) {
        this.formError = true;
        this.setState({
          newHomeworkError: "Homework description is required"
        });
      } else {
        this.setState({
          newHomeworkError: ""
        });
        this.formError = false;
      }
      return this.formError;
    };

    return (
      <Aux>
        <div className="lightbox" />
        <div
          className="modal show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "inline-block" }}
        >
          <div
            className="modal-dialog modal-lg fadeInDown animated"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Add Homework to {this.props.activeClass.name}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group" onKeyPress={enterPressed}>
                  <label htmlFor="homework">Homework Description</label>
                  <input
                    className={"form-control" + homeworkNameInvalid}
                    placeholder="Please provide a brief description of your homework"
                    id="homework"
                    rows="3"
                    value={this.state.newHomework}
                    onChange={changeHomeworkHandler}
                    autoFocus
                  />
                  <div className="is-invalid">
                    {this.state.newHomeworkError}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col text-right">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={this.props.closeModal}
                    >
                      Cancel
                    </button>
                    {this.formError ? (
                      <button
                        type="button"
                        className="btn btn-primary inactive"
                        id="callToAction"
                      >
                        Add Homework
                      </button>
                    ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          id="callToAction"
                          onClick={() => {
                            this.props.closeModal();
                            this.props.createHomework(this.state.newHomework);
                          }}
                        >
                          Add Homework
                      </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

export default CreateHomeworkModal;
