import * as actionTypes from "./../actions/actiontypes";

const initialState = {
  homeworks: [
  ],
  selectedHomework: {},
  loadHomework: false
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    // CREATE HOMEWORK
    case actionTypes.ASSIGNHOMEWORK:
      // Get new homework description
      const newHomework = action.newHomework;
      // Get the currently active class
      const activeClass = action.activeClass.key;
      // Get users current browser data
      const dateCreated = action.date;
      // Generare a new UID
      const key = action.guid;
      // Create a new homework object
      const homework = {
        key: key,
        class: activeClass,
        dateCreated: dateCreated,
        description: newHomework
      };
      // Get the current homework array
      const homeworks = JSON.parse(JSON.stringify(state.homeworks));
      // Combine current homework with new homework object.
      homeworks.push(homework);

      return {
        ...state,
        homeworks: homeworks
      };

    // SAVE HOMEWORK
    case actionTypes.SAVEHOMEWORKS: {
      // Get affected homework Object
      const homework = JSON.parse(JSON.stringify(state.homeworks)).filter(
        homework => {
          return homework.key === action.homeworkKey;
        }
      );
      // Update Homework Description
      homework[0].description = action.newDescription;
      // Get all other homework
      const allOtherHomework = JSON.parse(
        JSON.stringify(state.homeworks)
      ).filter(homework => {
        return homework.key !== action.homeworkKey;
      });
      //Combine homework
      const newHomework = allOtherHomework.concat(homework);
      return {
        ...state,
        homeworks: newHomework
      };
    }

    // DELETE HOMEWORK
    case actionTypes.DELETEHOMEWORKS: {
      // Get all homework except one being deleted
      const homeworks = JSON.parse(JSON.stringify(state.homeworks)).filter(
        homework => {
          return homework.key !== action.homeworkKey;
        }
      );
      return {
        ...state,
        homeworks: homeworks
      };
    }

    // SELECT HOMEWORK
    case actionTypes.SELECTHOMEWORKS: {
      // Get homework object that is being selected
      let selectedHomework = {};
      // If you selected homework, filter through homework and find it
      if (action.homeworkID !== "") {
        selectedHomework = action.homeworks.filter(homework => {
          return homework.id === action.homeworkID;
        });
        selectedHomework = selectedHomework[0];
      } else {
        selectedHomework = {};
      }
      return {
        ...state,
        selectedHomework: selectedHomework
      };

    }

    //DELETE HOMEWORKS IN CLASS
    case actionTypes.DELETEHOMEWORKSINCLASS: {
      const unaffectedHomework = JSON.parse(
        JSON.stringify(state.homeworks)
      ).filter(homework => {
        return homework.class !== action.activeClass.key;
      });
      return {
        ...state,
        homeworks: unaffectedHomework
      };
    }
    // DESELECT HOMEWORK
    case actionTypes.DESELECTHOMEWORK:
      return {
        ...state,
        selectedHomework: {}
      };

      case actionTypes.LOADHOMEWORK: {
        return {
          ...state,
          loadHomework: true,
        };
      }
      case actionTypes.LOADHOMEWORKCOMPLETE: {
  
        return {
          ...state,
          loadHomework: false,
        };
      }
    
      
    default:
      return state;
  }
};

export default reducer;
