import * as actiontypes from './../actions/actiontypes';

const initialState = {
  tutorials: false
};

const reducer = (state = initialState, action) => {
  switch (actiontypes) {

    case actiontypes.SETTUTORIALS:

      return {
        ...state,
      };

    default:
      return state;
  }
}
export default reducer;

