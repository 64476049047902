import * as actiontypes from "./../actions/actiontypes";

const initialState = {
  loginError: "",
  authenticating: false,
  registerError: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // LOGIN USER
    case actiontypes.AUTHSUCCESS: {
      return {
        ...state,
        authenticating: false
      };
    }
    case actiontypes.REGISTER: {
      return state;
    }
    case actiontypes.AUTHSTART: {
      return {
        ...state,
        authenticating: true
      };
    }
    case actiontypes.AUTHFAIL: {
      return {
        ...state,
        loginError: action.err,
        isError: true,
        authenticating: false
      };
    }
    case actiontypes.REGFAIL: {
      return {
        ...state,
        registerError: action.err,
        isError: true,
        authenticating: false
      };
    }
    case actiontypes.AUTHERRORCLEAR: {
      return {
        ...state,
        loginError: ""
      };
    }
    case actiontypes.LOGOUT: {
      return {
        ...state
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
