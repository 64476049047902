import * as actiontypes from "./../actions/actiontypes";

const initialState = {
  loadClasses: false,
  initialize: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // CREATE CLASS
    case actiontypes.CREATECLASS: {
      // let classes = JSON.parse(JSON.stringify(state.classes));
      // const classID = guid();
      // const newClass = {
      //   key: classID,
      //   name: action.newClassName
      // };
      // classes.push(newClass);
      // return {
      //   ...state,
      //   classes: classes,
      //   activeClass: newClass
      // };
      break;
    }

    // SELECT CLASS
    case actiontypes.SELECTCLASS: {
      return {
        ...state,
        activeClass: action.selectedClassName
      };
    }

    // SAVE CLASS
    case actiontypes.SAVECLASS: {
      // Get active class object from state
      const activeClass = JSON.parse(JSON.stringify(state.classes)).filter(
        singleClass => {
          return singleClass.name === state.activeClass.name;
        }
      );
      // Change name of active class
      activeClass[0].name = action.newClassName;
      // Get all inactive classes
      const inactiveClasses = JSON.parse(JSON.stringify(state.classes)).filter(
        singleClass => {
          return singleClass.name !== state.activeClass.name;
        }
      );
      //Combine inactive and active class objects
      const updatedClasses = inactiveClasses.concat(activeClass);
      return {
        classes: updatedClasses,
        activeClass: activeClass[0]
      };
    }

    //DELETE CLASS
    case actiontypes.DELETECLASS: {
      // // Get Active Class
      // const activeClass = state.activeClass;
      // // get classes that are not being deleted
      // const newClasses = JSON.parse(JSON.stringify(state.classes)).filter(
      //   singleClass => {
      //     return singleClass.key !== activeClass.key;
      //   }
      // );

      // return {
      //   ...state,
      //   classes: newClasses,
      //   activeClass: newClasses[0]
      // };
      return {};
    }
    case actiontypes.LOADCLASSES: {
      return {
        ...state,
        loadClasses: false,
        initialize: true
      };
    }
    case actiontypes.LOADCLASSESCOMPLETE: {

      return {
        ...state,
        loadClasses: true,
        initialize: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
