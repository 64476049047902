import React, { Component } from "react";
import Aux from "../../hoc/Aux";

export class EditHomeworkModal extends Component {
  state = {
    homeworkDescription: this.props.description,
    homeworkDescriptionError: "",
    queueDelete: false
  };
  // Controls dom to show delete gate
  queueDelete = () => {
    this.setState({
      queueDelete: !this.state.queueDelete
    });
  };

  formError = false;

  render() {
    let homeworkNameInvalid = "";
    if (this.state.homeworkDescriptionError !== "") {
      homeworkNameInvalid = " is-invalid";
    }

    const editHomeworkChange = event => {
      this.setState({
        homeworkDescription: event.target.value
      });
      validation(event.target.value);
    };

    const enterPressed = event => {
      var code = event.keyCode || event.which;
      if (code === 13) {
        const err = validation(this.state.homeworkDescription);
        if (!err) {
          this.props.closeModal();
          this.props.saveHomework(
            this.props.homeworkID,
            this.state.homeworkDescription
          );
        }
      }
    };

    //Validate Homework Description
    const validation = homeworkDescription => {
      let newHomeworkDescription = homeworkDescription;
      if (newHomeworkDescription.length === 0) {
        this.formError = true;
        this.setState({
          homeworkDescriptionError: "Homework description is required"
        });
      } else {
        this.setState({
          homeworkDescriptionError: ""
        });
        this.formError = false;
      }
      return this.formError;
    };

    return (
      <Aux>
        <div className="lightbox" />
        <div
          className="modal show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "inline-block" }}
        >
          <div
            className="modal-dialog modal-lg fadeInDown animated"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Homework</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group" onKeyPress={enterPressed}>
                  <label htmlFor="homework">Homework Description</label>
                  <input
                    className={"form-control" + homeworkNameInvalid}
                    id="homework"
                    rows="3"
                    value={this.state.homeworkDescription}
                    onChange={editHomeworkChange}
                    autoFocus
                  />
                  <div className="is-invalid">
                    {this.state.homeworkDescriptionError}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row justify-content-end">

                  {this.state.queueDelete === false ? (
                    <Aux>
                      <div className="col-4">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={this.queueDelete}
                        >
                          Delete Homework
                        </button>
                      </div>
                    </Aux>
                  ) : (
                      <div className="col">
                        <div className="animated fadeIn">
                          <div className="deleteWarning">Are you sure?</div>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              this.props.closeModal();
                              this.props.deleteHomework(this.props.homeworkID);
                            }}
                          >
                            Yes
                        </button>
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={this.queueDelete}
                          >
                            No
                        </button>
                        </div>
                      </div>
                    )}
                  {!this.state.queueDelete ?
                    <div className="col-8 text-right">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={this.props.closeModal}
                      >
                        Cancel
                    </button>
                      {this.formError ? (
                        <button
                          type="button"
                          className="btn btn-primary inactive"
                        >
                          Save
                      </button>
                      ) : (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              this.props.closeModal();
                              this.props.saveHomework(
                                this.props.homeworkID,
                                this.state.homeworkDescription
                              );
                            }}
                          >
                            Save
                      </button>
                        )}
                    </div>
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux >
    );
  }
}

export default EditHomeworkModal;
