import firebase from "firebase";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD3RF2NtEiVjDArw2SMWmGv5USCwk01Gtw",
  authDomain: "homeworkchecker-957f5.firebaseapp.com",
  databaseURL: "https://homeworkchecker-957f5.firebaseio.com",
  projectId: "homeworkchecker-957f5",
  storageBucket: "homeworkchecker-957f5.appspot.com",
  messagingSenderId: "685441878162"
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

const settings = {
  timestampsInSnapshots: true
};

firestore.settings(settings);

export default firebase;
