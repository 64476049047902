import React from "react";
import Checker from "./checker/checker";

const Student = props => {

  let filteredHomework = null
  if (props.studentHomework) {
    filteredHomework = props.studentHomework.filter(homework => {
      return homework.id === props.selectedHomework.id;
    });
  }

  return (
    <tr className="d-flex student-row">
      <td
        className="col-3 col-sm-4 truncate name"
        onClick={() => props.openStudent(props.studentID)}
      >
        {props.lname}
      </td>
      <td
        className="col-3 col-sm-4 truncate name"
        onClick={() => props.openStudent(props.studentID)}
      >
        {props.fname}
      </td>

      {props.selectedHomework.id ? (
        <td className="col-6 col-sm-4 text-center">
          <Checker
            studentID={props.studentID}
            checkHomework={(value, studentID, homeworkID) =>
              props.checkHomework(value, studentID, homeworkID)
            }
            homework={filteredHomework}
          />
        </td>
      ) : <td className="col-6 col-sm-4"> </td>}

    </tr>
  );
};

export default Student;
