import React, { Component } from "react";
import "./primaryNav.css";
import Aux from "../hoc/Aux";
import logo from "../../assets/images/HomeworkChecker-Logo.png";

export class PrimaryNav extends Component {
  state = {
    menuOpen: false
  };
  render() {
    const togglMenuHandler = () => {
      this.setState({
        menuOpen: !this.state.menuOpen
      });
    };

    return (
     
      <nav className="primary-navbar navbar">

        <a className="navbar-brand" href="">
          <img src={logo} alt="Homework Checker Logo" />
        </a>
        <div className="dropdown" onClick={togglMenuHandler}>
          {this.props.userProfileImage ? (
            <div className="avatar-background">
              <img src={this.props.userProfileImage} alt="account avatar" />
            </div>
          ) : (
              <i className="material-icons" onClick={togglMenuHandler}>
                account_circle
              </i>
            )}
          {this.state.menuOpen ? (
            <Aux>
              <div className="dropdown-menu-backdrop" />
              <div
                className="dropdown-menu show"
                aria-labelledby="dropdownMenuButton"
              >
                <span className="user-email">{this.props.user}</span>
                <hr />
                <div
                  className="dropdown-item link"
                  onClick={this.props.logout}
                >
                  Logout
                  </div>
              </div>
            </Aux>
          ) : null}
        </div>
      </nav>

    );
  }
}

export default PrimaryNav;
