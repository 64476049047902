import React from 'react';
import "./tutorial.css";

const Tutorial = (props) => {

  const tutorialStates = [
    { title: "", description: "" },
    { title: "Welcome to Homework Checker", description: "Let's start by creating a class" },
    { title: "Your class is open!", description: "It is now time to add students" },
    { title: "Great! Your students have been added", description: "Now let's create a homework assignment" },
    { title: "Homework has now been added", description: "Select a homework assignment above to begin checking homework" },
    { title: "Now you can check homework", description: `Use the buttons to select wether the students has completed their homework. You can also select the students' name to see their full report.` },
  ]



  return (
    <div className={"tutorial-wrapper " + props.tutorialAlignment}>
      <div className={"arrowWrapper " + props.arrowAlignment}>
        <div className="arrow">
          <div className="circle"></div>
          <div className="arrowLine"></div>
        </div>
      </div>
      <div className="cardWrapper">
        <div className="card tutorial">
        <div className="tutorial-header">
          <div className="title">{tutorialStates[props.state].title}</div>
          <button type="button" className="close" onClick={props.skip}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div className="body">{tutorialStates[props.state].description}</div>
        </div>
      </div>
    </div>
  )
}

export default Tutorial
