// Authentication
export const LOGINUSER = "LOGINUSER";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const REGFAIL = "REGFAIL";
export const LOGINUSERWITHGOOGLE = "LOGINUSERWITHGOOGLE";

export const AUTHSTART = "AUTHSTART";
export const AUTHSUCCESS = "AUTHSUCCESS";
export const AUTHFAIL = "AUTHFAIL";
export const AUTHERRORCLEAR = "AUTHERRORCLEAR";
export const FORGOTPASSWROD = "FORGOTPASSWORD";

// Classes
export const CREATECLASS = "CREATECLASS";

export const LOADCLASSES = "LOADCLASSES";
export const LOADCLASSESCOMPLETE = "LOADCLASSESCOMPLETE";
export const SELECTCLASS = "SELECTCLASS";
export const SAVECLASS = "SAVECLASS";
export const DELETECLASS = "DELETECLASS";

// Students
export const LOADSTUDENTS = "LOADSTUDENTS";
export const LOADSTUDENTSCOMPLETE = "LOADSTUDENTSCOMPLETE";
export const CREATESTUDENTS = "CREATESTUDENTS";
export const CHECKHOMEWORK = "CHECKHOMEWORK";
export const DELETESTUDENTSINCLASS = "DELETESTUDENTSINCLASS";
export const DELETESTUDENT = "DELETESTUDENT";
export const RENAMESTUDENT = "RENAMESTUDENT";

//Homeworks
export const LOADHOMEWORK = "LOADHOMEWORK";
export const LOADHOMEWORKCOMPLETE = "LOADHOMEWORKCOMPLETE";
export const ASSIGNHOMEWORK = "ASSIGNHOMEWORK";
export const SELECTHOMEWORKS = "SELECTHOMEWORKS";
export const DESELECTHOMEWORK = "DESELECTHOMEWORK";
export const SAVEHOMEWORKS = "SAVEHOMEWORKS";
export const DELETEHOMEWORKS = "DELETEHOMEWORKS";
export const DELETEHOMEWORKSINCLASS = "DELETEHOMEWORKSINCLASS";

//TUTORIALS
export const SETTUTORIALS = "SETTUTORIALS";
export const SKIPTUTORIALS = "SKIPTUTORIALS";

