import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

import Login from "./containers/Login/login";
import Assembly from "./containers/assembly";
import SetPassword from "./containers/setpassword/setpassword";
import Register from "./containers/Register/register";
import ForgotPassword from "./containers/forgotpassword/forgotpassword";
// import SetPassword from "./containers/setpassword/setpassword";

class App extends Component {
  render() {
    return (
      <div className="App">
        <ReduxToastr
          position="bottom-right"
          transitionIn="bounceIn"
          transitionOut="fadeOut"
        />
        <Route path="/" exact component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/app" component={Assembly} />
        <Route path="/setpassword" component={SetPassword} />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDisptachToProps = dispatch => {
  return {};
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDisptachToProps
  )(App)
);
