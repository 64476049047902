import React, { Component } from "react";
import Aux from "./../../hoc/Aux";
import "./../modal.css";

export class CreateClassModal extends Component {
  state = {
    newClassName: "",
    newClassNameError: ""
  };

  classNameChangeHandler = event => {
    const newClassName = event.target.value;
    this.setState({
      newClassName: newClassName
    });
    this.validation(newClassName);
  };

  createClass = () => {
    const err = this.validation(this.state.newClassName);
    if (!err) {
      this.props.createClassHandler(this.state.newClassName);
      this.props.closeModal();
    }
  };

  validation = newClassName => {
    let isError = false;
    // Clear errors
    this.setState({
      newClassNameError: ""
    });
    // Class name expexted format
    const classNameRegex = /^[a-z\d\s]+$/i;
    // Create error container
    let errors = {};
    // Does it have symbols?
    if (!classNameRegex.test(newClassName)) {
      errors.newClassNameError = "Pleae only use letters and numbers";
      isError = true;
    }
    // Is it empty?
    if (newClassName.length < 1) {
      errors.newClassNameError = "Class name is required";
      isError = true;
    }
    this.setState({
      ...errors
    });
    return isError;
  };

  enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.createClass();
    }
  };

  render() {
    let classNameInvalid = "";
    if (this.state.newClassNameError !== "") {
      classNameInvalid = " is-invalid";
    }

    return (
      <Aux>
        <div className="lightbox" />
        <div
          className="modal show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "inline-block" }}
        >
          <div
            className="modal-dialog modal-lg fadeInDown animated"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Class</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="form-group col-12 col-sm-6"
                  onKeyPress={this.enterPressed}
                >
                  <label htmlFor="className">Class Name</label>
                  <input
                    type="text"
                    className={"form-control" + classNameInvalid}
                    id="className"
                    placeholder="Enter your class name"
                    value={this.state.newClassName}
                    onChange={this.classNameChangeHandler}
                    autoFocus
                  />

                  <div className="is-invalid">
                    {this.state.newClassNameError}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row justify-content-end">
                  <div className="col text-right">
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={this.props.closeModal}
                    >
                      Cancel
                    </button>
                    {this.state.newClassName.length > 0 &&
                      this.state.newClassNameError.length === 0 ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            this.createClass();
                          }}
                        >
                          Create Class
                      </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary inactive"
                        >
                          Create Class
                      </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}

export default CreateClassModal;
