import React from "react";

const Checker = props => {
  let yes = "";
  let na = "";
  let no = "";


  if (props.homework[0].check === "yes") {
    yes = " focus";
  } else if (props.homework[0].check === "no") {
    no = " focus";
  } else if (props.homework[0].check === "na") {
    na = " focus";
  }

  return (
    <div
      className="btn-group animated fadeIn"
      role="group"
      aria-label="Basic example"
    >
      <button
        type="button"
        className={"btn btn-secondary btn-yes" + yes}
        data-value="yes"
        data-studentkey={props.studentID}
        onClick={() =>
          props.checkHomework("yes", props.studentID, props.homework[0].id)
        }
      >
        Yes
      </button>
      <button
        type="button"
        className={"btn btn-secondary btn-na" + na}
        data-value="na"
        data-studentkey={props.studentID}
        onClick={() =>
          props.checkHomework("na", props.studentID, props.homework[0].id)
        }
      >
        N/A
      </button>
      <button
        type="button"
        className={"btn btn-secondary btn-no" + no}
        data-value="no"
        data-studentkey={props.studentID}
        onClick={() =>
          props.checkHomework("no", props.studentID, props.homework[0].id)
        }
      >
        No
      </button>
    </div>
  );
};

export default Checker;
