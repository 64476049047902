import * as actionTypes from "../actions/actiontypes";
import getCurrentDate from "../../assets/js/date";
import guid from "../../assets/js/guid";
import { toastr } from "react-redux-toastr";
import { objectToArray } from "../../assets/js/helpers";
import { incrementTutorial } from "./tutorials";


export const assignHomework = (newHomework, activeClass, uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch(loadHomework());
    const currentStep = getState().firestore.data[uid].AppData.localUI.tutorials.step;
    // Prepare variables for construction
    const GUID = guid();
    const date = getCurrentDate();
    const firestore = getFirestore();

    //Get latest students from firebase
    await firestore.get(`${uid}/AppData/students`)

    // Get list of students that need homework assigned
    const students = objectToArray(getState().firestore.data[uid].AppData.students);
    if (students) {
      const filteredStudents = students.filter((student) => {
        return student.class === activeClass.id
      })

      //Get List of all current homework assigned to students
      for (let i = 0; i < filteredStudents.length; i++) {

        // Set base homework check object for new homework
        let newHomeworkCheck = { homework: [{ id: GUID, check: "na" }] }

        // Store current homework for student in loop
        let currentHomework = filteredStudents[i].homework;

        // If there is homework, add this to the baseline homework objet
        if (currentHomework.length !== 0) {
          for (let i = 0; i < currentHomework.length; i++) {
            newHomeworkCheck.homework.push(currentHomework[i]);
          }
        }
        // Update student document with new homework object array
        const studentRef = firestore.collection(uid).doc("AppData").collection("students").doc(filteredStudents[i].id);
        await studentRef.update(newHomeworkCheck);
      }
    }

    //Create objects for assignment
    const homework = {
      id: GUID,
      class: activeClass.id,
      dateCreated: date,
      description: newHomework
    };

    // Homework document reference
    const homeworkRef = firestore.collection(uid).doc("AppData").collection("homework").doc(GUID);
    // Build a batch process for assigning homework
    await homeworkRef.set(homework).then(() => {
      if (currentStep === 3) {
        dispatch(incrementTutorial(uid, currentStep));
      }
      toastr.success(
        "Succes!",
        `Homework was added`
      )
      dispatch(loadHomeworkComplete());
    }).catch(() => {
      toastr.error(
        "Uh Oh!",
        `Something went wrong`
      )
      dispatch(loadHomeworkComplete());
    })
  }
};

export const saveHomework = (homeworkID, newDescription, uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch(loadHomework());
    const firestore = getFirestore();
    var homeworkRef = firestore.collection(uid).doc("AppData").collection("homework").doc(homeworkID);
    try {
      await homeworkRef.update({ "description": newDescription }).then((response) => {
        toastr.success(
          "Success!",
          `Homework description was updated`
        );
        dispatch(loadHomeworkComplete());
      });
    } catch (error) {
      toastr.error(
        "Uh Oh!",
        `Something went wrong!`
      );
      dispatch(loadHomeworkComplete());
    }
  }
};

export const selectHomework = (homeworkID, homeworks) => {
  return { type: actionTypes.SELECTHOMEWORKS, homeworkID: homeworkID, homeworks: homeworks };
}

export const deselectHomework = () => {
  return { type: actionTypes.DESELECTHOMEWORK };
};


export const deleteHomework = (homeworkID, activeClass, uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch(loadHomework());
    const firestore = getFirestore();
    let homeworkRef = firestore.collection(uid).doc("AppData").collection("homework").doc(homeworkID);

    const deleteHomework = await homeworkRef.delete()

    // Update firestore when due to bug in react-redux-firebase
    const updateFirestoreHomework = await firestore.get(`${uid}/AppData/homework/`)

    await Promise.all([
      deleteHomework, updateFirestoreHomework
    ]).then(() => {
      deselectHomework();
      toastr.warning(
        "Success!",
        `Homework was deleted`
      );
      dispatch(loadHomeworkComplete());
    });

    // Get list of students that need homework deleted
    const students = objectToArray(getState().firestore.data[uid].AppData.students);

    // If there is a list of students
    if (students) {
      const filteredStudents = students.filter((student) => {
        return student.class === activeClass.id
      })

      //Get List of all current homework assigned to students
      for (let i = 0; i < filteredStudents.length; i++) {
        // Store current homework for student in loop
        let homework = filteredStudents[i].homework;
        // If there is homework, add this to the baseline homework object
        let currentHomework = JSON.parse(JSON.stringify(homework));
        const filteredHomework = currentHomework.filter((singleHomework) => {
          return (homeworkID !== singleHomework.id)
        })

        // Update student document with new homework object array
        const studentRef = firestore.collection(uid).doc("AppData").collection("students").doc(filteredStudents[i].id);
        await studentRef.update({ "homework": filteredHomework });
      }
    }
  }
}

// deletes all homework associated with a class when a class is deleted
export const deleteHomeworksInClass = activeClass => {
  return { type: actionTypes.DELETEHOMEWORKSINCLASS, activeClass: activeClass };
};

export const loadHomework = () => {
  return { type: actionTypes.LOADHOMEWORK };
};

export const loadHomeworkComplete = () => {
  return { type: actionTypes.LOADHOMEWORKCOMPLETE };
};
