import React, { Component } from "react";
import { connect } from "react-redux";
import Aux from "../../components/hoc/Aux";
import { withFirestore } from "react-redux-firebase";
import { objectToArray } from "../../assets/js/helpers";
import { assignHomework } from "../../store/actions/homeworks";
import { saveHomework } from "../../store/actions/homeworks";
import { selectHomework } from "../../store/actions/homeworks";
import { deleteHomework } from "../../store/actions/homeworks";
import { deselectHomework } from "../../store/actions/homeworks";

import "./homeworks.css";


import Homework from "./../../components/homework/homework";
import CreateHomeworkModal from "../../components/Modal/createHomework/createHomeworkModal";
import EditHomeworkModal from "../../components/Modal/editHomework/editHomeworkModal";
import Tutorial from "../../components/tutorial/tutorial";
import { incrementTutorial, skipTutorial } from "../../store/actions/tutorials";

export class Homeworks extends Component {
  state = {
    createHomeworkModal: false,
    editHomeworkModal: false,
    editHomeworkDescription: "",
    editHomeworkID: ""
  };
  async componentWillMount() {
    //Set Listeners
    await this.props.firestore.setListener(
      `${this.props.uid}/AppData/homework`
    );
    // Get Data
    await this.props.firestore.get(`${this.props.uid}/AppData/homework`);
  }
  render() {
    let options = null;
    let tutorials = null;
    let tutorialsStep = null;

    const mobileIncrementTutorialCheck = () => {
      if (tutorialsStep === 4) {
        this.props.incrementTutorialHandler(this.props.uid, tutorialsStep)
      }
    }

    const desktopIncrementTutorialCheck = (target) => {
      if (tutorialsStep === 4 && target.length > 0) {
        this.props.incrementTutorialHandler(this.props.uid, tutorialsStep)
      }
    }

    //Set tutorals information once we have the data
    if (
      this.props.userData &&
      this.props.userData.AppData.localUI.tutorials
    ) {
      tutorials = this.props.userData.AppData.localUI.tutorials.tutorials
      tutorialsStep = this.props.userData.AppData.localUI.tutorials.step
    }

    // Toggle Create Homework Modal
    const toggleCreateHomeModalHandler = () => {
      this.setState({
        createHomeworkModal: !this.state.createHomeworkModal
      });
    };

     // Toggle Edit Homework Modal
    const toggleEditHomeworkModalHandler = description => {
      this.setState({
        editHomeworkModal: !this.state.editHomeworkModal,
        editHomeworkDescription: description
      });
    };

    const provideHomeworkDetails = (description, homeworkID) => {
      this.setState({
        editHomeworkDescription: description,
        editHomeworkID: homeworkID
      });
    };

    const logHomeworkID = (homeworkID) => {
    }
    let activeClass = null;

    if (
      this.props.userData &&
      this.props.userData.AppData.localUI.activeClass
    ) {
      activeClass = this.props.userData.AppData.localUI.activeClass;
    }

    let homeworks = null;
    let homework = null;

    if (this.props.userData && this.props.userData.AppData.homework && activeClass) {
      homeworks = objectToArray(this.props.userData.AppData.homework);
      homeworks = homeworks.filter(homework => {
        return homework !== undefined;
      });
      const filteredHomework = homeworks.filter(homework => {
        return homework.class === activeClass.id;

      });

      // Homework options dropdown list
      options = filteredHomework.map(homework => {
        return (
          <option className="truncate" key={homework.id} id={homework.id} value={homework.description} >
            {homework.description}
          </option >
        );
      });
      const defaultOption = <option className="truncate " disabled key="defaultOption" value="default" >
        Select a homework
      </option >

      options.splice(0, 0, defaultOption)

      homework = filteredHomework.map(homework => {
        return (
          <Homework
            key={homework.id}
            selectedHomework={this.props.selectedHomework}
            homeworkID={homework.id}
            date={homework.dateCreated}
            description={homework.description}
            editHomework={toggleEditHomeworkModalHandler}
            provideHomeworkDetails={(description, homeworkID) =>
              provideHomeworkDetails(description, homeworkID)
            }
            selectHomework={(homeworkID, tutorialsStep) => {
              this.props.selectHomeworkHandler(homeworkID, tutorialsStep, this.props.uid);
              logHomeworkID(logHomeworkID);
            }
            }
          />
        );
      });
    }

    // This is middleware to get the correct id of the selected homework
    const dropdownSelection = (event) => {
      let id = null
      // get the current homework for the active class
      const filteredHomework = homeworks.filter(homework => {
        return homework.class === activeClass.id;
      });
      // get the ID for the selected class based on description
      for (let i = 0; i < filteredHomework.length; i++) {
        if (filteredHomework[i].description === event.target.value) {
          id = filteredHomework[i].id;
        }
      }
      // Call selectHomework handler with correct data
      this.props.selectHomeworkHandler(
        id, homeworks, tutorialsStep, this.props.uid
      );
      mobileIncrementTutorialCheck();
    }

    return (
      <Aux>
        {
          activeClass && tutorialsStep !== 2 && tutorialsStep !== 1 && this.props.loadClasses ?
            <Aux>
              {
                this.state.createHomeworkModal ? (
                  <CreateHomeworkModal
                    closeModal={toggleCreateHomeModalHandler}
                    activeClass={activeClass}
                    createHomework={newHomework => {
                      this.props.assignHomeworkHandler(
                        newHomework,
                        activeClass,
                        this.props.uid
                      );
                    }}
                  />
                ) : null
              }
              {
                this.state.editHomeworkModal ? (
                  <EditHomeworkModal
                    key={this.state.editHomeworkKey}
                    homeworkID={this.state.editHomeworkID}
                    closeModal={toggleEditHomeworkModalHandler}
                    description={this.state.editHomeworkDescription}
                    saveHomework={(homeworkID, newDescription) => {
                      this.props.saveHomeworkHandler(homeworkID, newDescription, this.props.uid);
                    }}
                    deleteHomework={homeworkID => {
                      this.props.deselectHomeworkHandler();
                      this.props.deleteHomeworkHandler(
                        homeworkID,
                        activeClass,
                        this.props.uid
                      );
                    }}
                  />
                ) : null
              }


              {/* MOBILE DISPLAY */}
              <div className="mobile">
                <nav className="secondary-navbar homework homework-bar animated fadeInDown">
                  <div className="container-fluid">
                    <div className="row justify-content-between align-items-center">
                      <div className="col">
                        <h6>Homework</h6>
                          {/* Is there homework? */}
                          {homework && homework.length >= 1 ? (
                          <Aux>
                            {/* Is Homework Loaded? */}
                            {this.props.loadHomework ? (
                            <Aux className="animated fadeIn">
                              <hr />
                              <div className="classes-loader-wrapper">
                                <div className="loader dark classes-loader" />Loading</div>
                            </Aux>) :
                            <div className="align-self-center">
                              <form className="animated fadeIn">
                                <div className="form-group classes">
                                  <select
                                    className="form-control truncate"
                                    value={homework.description}
                                    defaultValue="default"
                                    onChange={dropdownSelection}>
                                    {options}
                                  </select>
                                  {/* Show tutorials */}
                                  {tutorials && tutorialsStep === 4 ?
                                    <Tutorial state={tutorialsStep} arrowAlignment="center" tutorialAlignment="rightAlignment" />
                                    : null}
                                </div>
                              </form>
                            </div>
                            }
                          </Aux>
                          ) :
                          <div className="align-self-center">
                            <div className="content-placeholder">
                              No homework Added
                              </div>
                          </div>
                          }
                      </div>
                        <div className="col-auto">
                          <div className="row no-gutters align-items-center">
                            <div className="col">
                              <span />
                            </div>
                            {Object.keys(this.props.selectedHomework).length !==
                              0 &&
                              this.props.selectedHomework.constructor === Object ? (
                                <div className="col">
                                  <div className="animated fadeIn">
                                    <button
                                      type="button"
                                      className="btn btn-link"
                                      onClick={() => {
                                        toggleEditHomeworkModalHandler();
                                        provideHomeworkDetails(this.props.selectedHomework.description, this.props.selectedHomework.id);

                                      }}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </div>
                              ) : null}
                            <div className="col">
                              <div className="animated fadeIn">
                                <button
                                  type="button"
                                  className="btn btn-link"
                                  onClick={toggleCreateHomeModalHandler}
                                >
                                  Add
                                {tutorials && tutorialsStep === 3 ?
                                    <Tutorial state={tutorialsStep} arrowAlignment="right" tutorialAlignment="rightAlignment" />
                                    : null}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </nav>
              </div>


              {/* DESKTOP DISPLAY */}
              <div className="not-mobile">
                <div
                  className="card homework animated fadeInRight"
                  onClick={(event, Homeworks) => {
                    this.props.selectHomeworkHandler(event.target.id, homeworks);
                    desktopIncrementTutorialCheck(event.target.id);
                  }}
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title">Homework</h5>
                      </div>
                      <div className="col text-right">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={toggleCreateHomeModalHandler}
                        >
                          Add Homework
                        </button>
                        {tutorials && tutorialsStep === 3 ?
                            <Tutorial skip={() => this.props.skipTutorialHandler(this.props.uid, 3)} state={tutorialsStep} arrowAlignment="center" tutorialAlignment="rightAlignment" />
                            : null}
                      </div>
                      </div>
                      {homework && homework.length >= 1 ? (
                        <table className="table homework">
                          <thead>
                            <tr className="d-flex header-row">
                              <th className="col-3">Date</th>
                              <th className="col-5">Description</th>
                              <th className="col-4 text-center" />
                            </tr>
                          </thead>
                          {!this.props.loadHomework ?
                            <tbody>{homework}
                             {tutorials && tutorialsStep === 4 ?
                              <Tutorial 
                              skip={() => this.props.skipTutorialHandler(this.props.uid, 4)}
                              state={tutorialsStep} tutorialAlignment="rightAlignment" arrowAlignment="center" />
                              : null}</tbody>
                            :  <Aux className="animated fadeIn">
                          
                            <div className="classes-loader-wrapper">
                            <div className="loader dark classes-loader" />Loading
                            </div>
                          </Aux>}
                         
                        </table>
                      ) : null
                     }
                    </div>
                  </div>
                </div>
         
            </Aux >
            : null}
      </Aux >
    );
  }
}

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    userData: state.firestore.data[state.firebase.auth.uid],
    selectedHomework: state.homeworks.selectedHomework,
    loadClasses: state.classes.loadClasses,
    loadHomework: state.homeworks.loadHomework
  };
};

const mapDisptachToProps = dispatch => {
  return {
    assignHomeworkHandler: (newHomework, activeClass, uid) =>
      dispatch(assignHomework(newHomework, activeClass, uid)),
    saveHomeworkHandler: (homeworkID, newDescription, uid) =>
      dispatch(saveHomework(homeworkID, newDescription, uid)),
    selectHomeworkHandler: (homeworkID, homeworks, tutorialsStep, uid) => dispatch(selectHomework(homeworkID, homeworks, tutorialsStep, uid)),
    deselectHomeworkHandler: () => dispatch(deselectHomework()),
    deleteHomeworkHandler: (homeworkID, activeClass, uid) =>
      dispatch(deleteHomework(homeworkID, activeClass, uid)),
    incrementTutorialHandler: (uid, tutorialsStep) => (dispatch(incrementTutorial(uid, tutorialsStep))),
    skipTutorialHandler: (uid, step) => dispatch(skipTutorial(uid, step))
  };
};

export default withFirestore(connect(
  mapStateToProps,
  mapDisptachToProps
)(Homeworks));
