import React, { Component } from "react";
import Aux from "../../hoc/Aux";

export class EditClassModal extends Component {
  state = {
    newClassName: this.props.activeClass.name,
    editClassNameError: "",
    queueDelete: false
  };

  editClassNameChangeHandler = event => {
    const editClassName = event.target.value;
    this.setState({
      newClassName: editClassName
    });
    this.validation(editClassName);
  };

  editClass = () => {
    const isError = this.validation(this.state.newClassName);
    if (!isError) {
      this.props.closeModal();
      this.props.saveClass(this.state.newClassName);
    }
  };

  validation = editClassName => {
    let isError = false;
    // Clear errors
    this.setState({
      editClassNameError: ""
    });
    // Class name expexted format
    const classNameRegex = /^[a-z\d\s]+$/i;
    // Create error container
    let errors = {};
    // Does it have symbols?
    if (!classNameRegex.test(editClassName)) {
      errors.editClassNameError = "Pleae only use letters and numbers";
      isError = true;
    }
    // Is it empty?
    if (editClassName.length < 1) {
      errors.editClassNameError = "Class name is required";
      isError = true;
    }
    this.setState({
      ...errors
    });
    return isError;
  };

  queueDelete = () => {
    this.setState({
      queueDelete: !this.state.queueDelete
    });
  };

  enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.editClass();
    }
  };

  render() {
    let editClassNameInvalid = "";
    if (this.state.editClassNameError !== "") {
      editClassNameInvalid = " is-invalid";
    }
    return (
      <Aux>
        <div className="lightbox" />
        <div
          className="modal show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "inline-block" }}
        >
          <div
            className="modal-dialog modal-lg fadeInDown animated"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Edit {this.props.activeClass.name} Class
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group" onKeyPress={this.enterPressed}>
                  <label htmlFor="className">Class Name</label>
                  <input
                    type="text"
                    className={"form-control col-12 col-sm-6" + editClassNameInvalid}
                    id="className"
                    value={this.state.newClassName}
                    onChange={this.editClassNameChangeHandler}
                    autoFocus
                  />
                  <div className="is-invalid">
                    {this.state.editClassNameError}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row justify-content-end">
                  {this.state.queueDelete === false ? (
                    <Aux>
                      <div className="col-4">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={this.queueDelete}
                        >
                          Delete Class
                        </button>
                      </div>
                    </Aux>
                  ) : (
                      <div className="col">
                        <div className="animated fadeIn">
                          <div className="deleteWarning">Are you sure?</div>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              this.props.deleteClass();
                              this.props.closeModal();
                            }}
                          >
                            Yes
                        </button>
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={this.queueDelete}
                          >
                            No
                        </button>
                        </div>
                      </div>
                    )}
                  {!this.state.queueDelete ?
                    <div className="col-8 text-right">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={this.props.closeModal}
                      >
                        Cancel
                    </button>
                      {this.state.newClassName.length > 0 &&
                        this.state.editClassNameError.length === 0 ? (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              this.editClass();
                            }}
                          >
                            Save
                      </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary inactive"
                          >
                            Save
                      </button>
                        )}
                    </div>
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux >
    );
  }
}

export default EditClassModal;
