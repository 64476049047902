import guid from "../../assets/js/guid";
import { toastr } from "react-redux-toastr";
import { objectToArray } from "../../assets/js/helpers";
import { incrementTutorial } from "./tutorials";
import * as actionTypes from './actiontypes';


export const createStudents = (newStudents, activeClass, uid) => {
  
  return async (dispatch, getState, { getFirestore }) => {
    dispatch(loadStudents());
    const currentStep = getState().firestore.data[uid].AppData.localUI.tutorials.step;
    const homework = objectToArray(getState().firestore.data[uid].AppData.homework);
    let filteredHomework = null
    let homeworkArray = []

    if (homework) {
      filteredHomework = homework.filter((homework) => {
        return homework.class === activeClass.id
      })
      for (let i = 0; i < filteredHomework.length; i++) {
        homeworkArray[i] = { id: filteredHomework[i].id, check: "na" }
      }
    }

    const students = newStudents.map((student) => {
      if (!student.fname) {
        student.fname = ""
      }
      if (!student.lname) {
        student.lname = ""
      }
      return { id: guid(), fname: student.fname, lname: student.lname, class: activeClass.id, homework: homeworkArray }
    })

    const firebase = getFirestore();
    const batch = firebase.batch();
    for (let i = 0; i < students.length; i++) {
      var studentRef = firebase.collection(uid).doc("AppData").collection("students").doc(students[i].id);
      batch.set(studentRef, students[i]);
    }
    batch.commit().then(() => {
      if (currentStep === 2) {
        dispatch(incrementTutorial(uid, currentStep));
      }
      toastr.success(
        "Success!",
        `Students were added `
      );
      dispatch(loadStudentsComplete());
    }).catch((error) => {
      console.log(error);
      dispatch(loadStudentsComplete());
    })
  }

  // const stateClasses = objectToArray(getState().firestore.data[uid].AppData.classes);
  // let classes = null
  // // Clean up classes do to react-redux-firestore bug
  // if (stateClasses) {
  //   classes = stateClasses.filter((singleClass) => {
  //     return singleClass !== undefined
  //   })
}


export const checkHomework = (value, studentID, homeworkID, uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    const currentStep = getState().firestore.data[uid].AppData.localUI.tutorials.step;
    const firebase = getFirestore();
    // force database to update students and store data of students to variab;e
    await firebase.collection(uid).doc("AppData").collection("students").doc(studentID).get()
    const studentHomework = getState().firestore.data[uid].AppData.students[studentID].homework;
    // Find the index of the homework
    for (let i = 0; i < studentHomework.length; i++) {
      if (studentHomework[i].id === homeworkID) {
        studentHomework[i] = { id: homeworkID, check: value }
      }
    }
    const homeworkCheckRef = firebase.collection(uid).doc("AppData").collection("students").doc(studentID);
    try {
      await homeworkCheckRef.update({ "homework": studentHomework }).then(response => {
        if (currentStep === 5) {
          dispatch(incrementTutorial(uid, currentStep));
        }
      })
    } catch (error) {

    }
  }
};

export const deleteStudent = (studentID, uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch(loadStudents());
    const firestore = getFirestore();
    // force database to update students and store data of students to variab;e
    await Promise.all([
      await firestore.collection(uid).doc("AppData").collection("students").doc(studentID).delete(),
      await firestore.get(`${uid}/AppData/students`)
    ]).then((response) => {
      toastr.warning(
        "Success!",
        `Student was deleted`
      );
      dispatch(loadStudentsComplete());
    }).catch((error) => {
      toastr.error(
        "Uh Oh!",
        `We were unable to delete the student`
      );
      dispatch(loadStudentsComplete());
    })

  }
};
export const renameStudent = (studentID, fname, lname, uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch(loadStudents());
    const firestore = getFirestore();
    await firestore.collection(uid).doc("AppData").collection("students").doc(studentID).update({ fname: fname, lname: lname })
      .then((response) => {
        toastr.success(
          "Success!",
          `Student was renamed`
        );
        dispatch(loadStudentsComplete());
      }).catch((error) => {
        toastr.error(
          "Uh Oh!",
          `We were unable to rename student`
        );
        dispatch(loadStudentsComplete());
      })
  }
}

export const loadStudents = () => {
  return { type: actionTypes.LOADSTUDENTS };
};

export const loadStudentsComplete = () => {
  return { type: actionTypes.LOADSTUDENTSCOMPLETE };
};