import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Aux from "../../components/hoc/Aux";
import Logo from "../../assets/images/Logo.png";
import {
    forgotPassword
} from "../../store/actions/authentication";

import "../Login/login.css"

export class ForgotPassword extends Component {
    state = {
        email: "",
        emailError: "",
    };

    render() {
        const validation = (type, value) => {
            if (type === "email") {
                const email = value;
                // Is the email field empty?
                if (email.length === 0) {
                    this.setState({
                        emailError: "Email is required"
                    });
                }
            }
        }
        const emailFormatCheck = () => {
            const emailRegex = /\S+@\S+\.\S+/;
            if (!emailRegex.test(this.state.email)) {
                this.setState({
                    emailError: "Invalid Email"
                });
            } else {
                this.props.forgotPasswordHandler(
                    this.state.email
                )
            }
            clearForm();
        }

        const clearForm = () => {
            this.setState({
                email: "",
                emailError: ""
            })
        }

        const emailChangeHandler = (event) => {
            this.setState({
                email: event.target.value,
                emailError: ""
            });
            validation("email", event.target.value);
        }


        if (this.props.isAuthenticated) {
            return <Redirect to="/app" />;
        }

        let emailIsInvalidClass = "";
        if (this.state.emailError !== "") {
            emailIsInvalidClass = " is-invalid";
        }


        return (

            <Aux>
                <nav className="primary-navbar navbar">
                    <div className="container-fluid">
                        <div className="loginheader">
                            <span> Don't have an account? </span>
                            <Link to="/register"><span className="line">Sign up today</span></Link>
                        </div>
                    </div>
                </nav>
                <div className="background" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6">
                            <div className="card login">
                                <div className="row justify-content-center">
                                    <div className="col-11 col-sm-8">
                                        <img alt="logo" className="logo" src={Logo} />
                                        <h4>Forgot Password</h4>
                                        <hr />
                                        <div className="form-group ">
                                            <div className="input-group-prepend">
                                                <i className={"material-icons float-left" + emailIsInvalidClass}>email </i>
                                                <input
                                                    type="text"
                                                    className={
                                                        "form-control login-input" + emailIsInvalidClass
                                                    }
                                                    autoFocus

                                                    ref="email"
                                                    placeholder="Enter your email"
                                                    value={this.state.email}
                                                    onChange={emailChangeHandler}
                                                />

                                            </div>

                                            {this.state.emailError.length > 0 ? (
                                                <label className="is-invalid animated fadeIn">
                                                    {this.state.emailError}
                                                </label>
                                            ) : null}
                                        </div>

                                        <div
                                            className="btn btn-primary col v-space"
                                            onClick={() => {
                                                emailFormatCheck()
                                            }}>
                                            Send Password
                                        </div>

                                        <div
                                            className="btn btn-link col"
                                            onClick={() => this.props.history.push("/")}>
                                            Cancel
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}
const mapStateToProps = state => {
    return {
        loginError: state.authentication.loginError,
        authenticating: state.authentication.authenticating,
        isAuthenticated: state.firebase.auth.uid
    };
};

const mapDispatchToProps = dispatch => {
    return {
        forgotPasswordHandler: (email) => dispatch(forgotPassword(email))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword);
