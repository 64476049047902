import React, { Component } from "react";
import { connect } from "react-redux";
import Aux from "../../components/hoc/Aux";
import { withFirestore } from "react-redux-firebase";
import { objectToArray } from "../../assets/js/helpers";

import { createStudents } from "../../store/actions/students";
import { deleteStudent } from "../../store/actions/students";
import { renameStudent } from "../../store/actions/students";
import { checkHomework } from "../../store/actions/students";
import Student from "../../components/student/student";
import CreateStudentModal from "../../components/Modal/createStudent/createStudentModal";
import "./students.css";
import StudentCard from "../../components/studentCard/studentCard";
import Tutorial from "../../components/tutorial/tutorial";
import { skipTutorial } from "../../store/actions/tutorials";

export class Students extends Component {
  state = {
    createStudentModal: false,
    viewStudentModal: false,
    openStudent: "",
    openStudentHomework: [],
    sorting: {
      lname: "asc",
      fname: "asc"
    }
  };

  async componentWillMount() {
    //Set Listeners
    await this.props.firestore.setListener(
      `${this.props.uid}/AppData/students`
    );

    // Get Data
    await this.props.firestore.get(`${this.props.uid}/AppData/students`);
    await this.props.firestore.get(`${this.props.uid}/AppData/homework`);
  }

  render() {

    
    let activeClass = null;
    let students = null;
    let homework = null;
    let studentList = null;
    let tutorials = null;
    let tutorialsStep = null;

    if (
      this.props.userData &&
      this.props.userData.AppData.localUI.activeClass
    ) {
      activeClass = this.props.userData.AppData.localUI.activeClass;
    }

    //set tutorals once we have the data
    if (
      this.props.userData &&
      this.props.userData.AppData.localUI.tutorials
    ) {
      tutorials = this.props.userData.AppData.localUI.tutorials.tutorials
      tutorialsStep = this.props.userData.AppData.localUI.tutorials.step
    }



    let sortKey = Object.keys(this.state.sorting)[0];


    const toggleCreateStudentModalHandler = () => {
      this.setState({
        createStudentModal: !this.state.createStudentModal
      });
    };

    const selectStudent = studentID => {
      this.setState({
        openStudent: studentID
      });
    };

    const toggleViewStudentModalHandler = () => {
      this.setState({
        viewStudentModal: !this.state.viewStudentModal
      });
    };

    if (this.props.userData && this.props.userData.AppData.students && activeClass) {
      students = objectToArray(this.props.userData.AppData.students);
      homework = objectToArray(this.props.userData.AppData.homework);
      // Filters through students and removes null due to react-redux-firestore bug
      const cleanedStudents = students.filter(student => {
        return student !== null && student !== undefined ;
      });

      let filteredStudents = cleanedStudents.filter(student => {
        return student.class === activeClass.id;
      });

      filteredStudents.sort((a, b) => {
        if (this.state.sorting[sortKey] === "asc") {
          return a[sortKey] > b[sortKey];
        } else {
          return a[sortKey] < b[sortKey];
        }
      });

      studentList = filteredStudents.map(student => {
        return (
          <Student
            key={student.id}
            studentID={student.id}
            fname={student.fname}
            lname={student.lname}
            studentHomework={student.homework}
            selectedHomework={this.props.selectedHomework}
            openStudent={student => {
              selectStudent(student);
              toggleViewStudentModalHandler();
            }}
            checkHomework={(value, studentID, homeworkID) =>
              this.props.checkHomeworkHandler(value, studentID, homeworkID, this.props.uid)
            }
          />
        );
      });
    }

    return (
      <Aux>
        {activeClass && tutorialsStep !== 1 && this.props.loadClasses ? (
          <Aux>
            {this.state.createStudentModal ? (
              <CreateStudentModal
                closeModal={toggleCreateStudentModalHandler}
                activeClass={activeClass}
                addAnotherStudent={this.addAnotherStudentHandler}
                createStudents={newStudents => {
                  this.props.createStudentHandler(
                    newStudents,
                    activeClass,
                    this.props.uid
                  );
                }}
              />
            ) : null}
            {this.state.viewStudentModal ? (
              <StudentCard
                closeModal={toggleViewStudentModalHandler}
                studentID={this.state.openStudent}
                students={students}
                homeworks={homework}
                deleteStudent={studentID =>
                  this.props.deleteStudentHandler(studentID, this.props.uid)
                }
                checkHomework={(value, studentID, homeworkID) =>
                  this.props.checkHomeworkHandler(
                    value, studentID, homeworkID, this.props.uid
                  )
                }
                renameStudent={(studentID, fname, lname) =>
                  this.props.renameStudentHandler(studentID, fname, lname, this.props.uid)
                }
              />
            ) : null}
            <div className="card animated fadeInUp">
              <div className="card-body student">
                <div className="container-fluid">
                  <div className="header-content">
                    <div className="row no-gutters justify-content-between align-items-center">
                      <div className="col">
                        <h5 className="card-title">Students</h5>
                      </div>
                      <div className="col text-right">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={toggleCreateStudentModalHandler}
                        >
                          Add Students
                          
                        </button>
                        {tutorials && tutorialsStep === 2 ?
                            <Tutorial 
                            skip={() => this.props.skipTutorialHandler(this.props.uid, 2)}
                            state={tutorialsStep}
                             arrowAlignment="right" 
                             tutorialAlignment="rightAlignment" />
                            : null}
                      </div>
                    </div>
                    <div className="row no-gutters mobile homework-details animated fadeIn">
                      {Object.keys(this.props.selectedHomework).length !==
                        0 &&
                        this.props.selectedHomework.constructor === Object ? (
                          <Aux>
                            <div className="row no-gutters">
                              <div className="col">Homework:&nbsp;</div>
                            </div>
                            <div className="row no-gutters">
                              <div className="col">{this.props.selectedHomework.dateCreated} - {this.props.selectedHomework.description}</div>
                            </div>
                          </Aux>) : null}
                    </div>
                  </div>
                  {studentList && studentList.length >= 1 ?  (
                    <Aux>
                      { this.props.loadStudents ? (
                        <Aux className="animated fadeIn">
                          <hr />
                          <div className="classes-loader-wrapper">
                          <div className="loader dark classes-loader" />Loading students</div>
                        </Aux> ) :
                      <table className="student-table table table-hover animated fadeIn table-reponsive">
                        <thead>
                          <tr className="d-flex">
                            <th className="col-3 col-sm-4 lname" id="lname">
                            </th>
                            <th className="col-3 col-sm-4 fname" id="fname">
                            </th>
                            {/* Is there valid selected homework? */}
                            {Object.keys(this.props.selectedHomework).length !==
                              0 &&
                              this.props.selectedHomework.constructor === Object ? (
                                <th className="col-6 col-sm-4 animated fadeIn checker text-center">
                                </th>
                              ) : (
                                <th className="col-6 col-sm-4 text-center" ></th>
                              )}
                          </tr>
                        </thead>
                        <tbody>{studentList}
                        {tutorials && tutorialsStep === 5 && Object.keys(this.props.selectedHomework).length !==
                  0 ?
                  <Tutorial 
                  skip={() => this.props.skipTutorialHandler(this.props.uid, 5)}
                  state={tutorialsStep} arrowAlignment="mid-right" tutorialAlignment="rightAlignment" />
                  : null}</tbody>
                              </table> 
                            }
                  </Aux> ) : null 
              }
              
                </div>
                
              </div>
            </div>
          </Aux>
        ) : null}
      </Aux>
    );
  }
}
const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    userData: state.firestore.data[state.firebase.auth.uid],
    selectedHomework: state.homeworks.selectedHomework,
    loadClasses: state.classes.loadClasses,
    loadStudents: state.students.loadStudents
  };
};

const mapDisptachToProps = dispatch => {
  return {
    createStudentHandler: (newStudents, activeClass, uid) =>
      dispatch(createStudents(newStudents, activeClass, uid)),
    checkHomeworkHandler: (value, studentID, homeworkID, uid, activeClass) =>
      dispatch(checkHomework(value, studentID, homeworkID, uid, activeClass)),
    deleteStudentHandler: (studentID, uid) => dispatch(deleteStudent(studentID, uid)),
    renameStudentHandler: (studentID, fname, lname, uid) =>
      dispatch(renameStudent(studentID, fname, lname, uid)),
      skipTutorialHandler: (uid, step) => dispatch(skipTutorial(uid, step))
  };
};

export default withFirestore(
  connect(
    mapStateToProps,
    mapDisptachToProps
  )(Students)
);
