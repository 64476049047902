export const objectToArray = obj => {
  if (obj) {
    return Object.values(obj);
  }
};

export const cleanArray = array => {
  array.filter(singleArray => {
    return singleArray !== undefined;
  })
}

