import React, { Component } from "react";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";
import { objectToArray } from "../../assets/js/helpers";

// components
import Aux from "../../components/hoc/Aux";
import CreateClassModal from "./../../components/Modal/createClass/createClassModal";
import EditClassModal from "./../../components/Modal/editClass/editClassModal";
import Tutorial from "../../components/tutorial/tutorial";
import {Button} from "react-bootstrap";
// actions
import { selectClass, createClass, loadClasses, loadClassesComplete,saveClass,deleteClass } from "../../store/actions/classes";

import { deselectHomework } from "../../store/actions/homeworks";
import { setTutorials, skipTutorial } from "../../store/actions/tutorials";

//css
import "./classes.css";

export class Classes extends Component {
  state = {
    createClassModal: false,
    editClassModal: false,
    classes: null,
    activeClass: null
  };

  async componentWillMount() {
    //Set Listeners for Active Class
    await this.props.firestore.setListener(
      `${this.props.uid}/AppData/localUI/activeClass`
    );
    //Set Listeners for Tutorials
    await this.props.firestore.setListener(
      `${this.props.uid}/AppData/localUI/tutorials`
    );
    //Set Listeners for Classes
    await this.props.firestore.setListener(`${this.props.uid}/AppData/classes`);

    // Get Data Classes
    await this.props.firestore.get(`${this.props.uid}/AppData/classes`);

    // Get Data Active Class
    await this.props.firestore.get(
      `${this.props.uid}/AppData/localUI/activeClass`
    );
    
    // Initialize Classes
    if (!this.props.initialize) {
      this.props.loadClassesHandler();
      await Promise.all([
        await this.props.firestore.get(`${this.props.uid}/AppData/classes`),
        await this.props.firestore.get(`${this.props.uid}/AppData/localUI/activeClass`),
        await this.props.firestore.get(`${this.props.uid}/AppData/localUI/tutorials`)
      ]).then(reponse => {
        this.props.loadClassesCompleteHandler();
      });
    }
    //Set Tutorials
    if (!this.props.userData.AppData.localUI.tutorials) {
      this.props.setTutorialsHandler(this.props.uid);
    }

  }
  render() {
    let classes = null;
    let activeClass = null;
    let tutorials = null;
    let tutorialsStep = null;
    let isClasses = false;
    let options = null;

    // Set variables if we have the data
    if (
      this.props.userData &&
      this.props.userData.AppData.localUI.activeClass &&
      this.props.userData.AppData.classes
    ) {
      activeClass = this.props.userData.AppData.localUI.activeClass;
      classes = objectToArray(this.props.userData.AppData.classes);
    }
    //set tutorals once we have the data
    if (
      this.props.userData &&
      this.props.userData.AppData.localUI.tutorials
    ) {
      tutorials = this.props.userData.AppData.localUI.tutorials.tutorials
      tutorialsStep = this.props.userData.AppData.localUI.tutorials.step
    }


    // If variables have been set, set confirmation flag to true
    if (this.props.userData && activeClass && classes) {
      isClasses = true;
      // Filters undefined objects due to react-redux-firestore bug
      classes = classes.filter(singleClass => {
        return singleClass !== undefined;
      });
    }

    // Create class options dropdown list
    if (isClasses) {
      options = classes.map(classes => {
        return (
          <option key={classes.id} value={classes.name}>
            {classes.name}
          </option>
        );
      });
    }

    const toggleCreateClassModalHandler = () => {
      this.setState({
        createClassModal: !this.state.createClassModal
      });
    };
    const toggleEditClassModalHandler = () => {
      this.setState({
        editClassModal: !this.state.editClassModal
      });
    };

    return (
      <Aux>
        {/* Modal */}
        {this.state.createClassModal ? (
          <CreateClassModal
            closeModal={toggleCreateClassModalHandler}
            createClassHandler={(newClassName, success) => {
              this.props.createClassHandler(newClassName, this.props.uid);
            }}
          />
        ) : null}
        {this.state.editClassModal ? (
          <EditClassModal
            activeClass={activeClass}
            closeModal={toggleEditClassModalHandler}
            saveClass={newClassName => {
              this.props.saveClassHandler(newClassName, this.props.students);
            }}
            deleteClass={() => {
              //  this.props.deselectHomeworkHandler();
              this.props.deleteClassHandler(activeClass, this.props.uid);
              // this.props.deleteHomeworkClassHandler(activeClass);
              // this.props.deleteStudentsInClassHandler(activeClass);
            }}
          />
        ) : null}
        {/* Class Bar */}

        {/* MOBILE DISPLAY */}
        <div className="mobile">
          <nav className="secondary-navbar classes animated fadeInDown">
            <div className="container-fluid">
              <div className="row justify-content-between align-items-center">
                <div className="col">
                  <h6>Classes</h6>
                  {/* Class Selection Dropdown */}
                  {!this.props.loadClasses || this.props.initialize ? (
                    <Aux>
                      <div className="classes-loader-wrapper">
                        <div className="loader dark classes-loader" />Loading
                      </div>
                    </Aux>
                  ) : (
                      <Aux>
                        {isClasses ? (
                          <Aux>
                            <div className="align-self-center">
                              <form className="animated fadeIn">
                                <div className="form-group classes">
                                  <select
                                    className="form-control"
                                    value={activeClass.name}
                                    onChange={event => {
                                      this.props.deselectHomeworkHandler();
                                      this.props.selectClassHandler(
                                        event.target.value, this.props.uid
                                      );
                                    }}
                                  >
                                    {options}
                                  </select>
                                </div>
                              </form>
                            </div>
                          </Aux>
                        ) : (
                            <Aux>
                              <div className="align-self-center">
                                <div className="classes-placeholder">
                                  No Classes
                                </div>
                              </div>
            
                            </Aux>
                          )}
                      </Aux>
                    )}
                </div>

 
                <div className="col-auto">
                  <div className="row no-gutters align-items-center">
                    <div className="col">
                      <span />
                    </div>
                    {isClasses ? (
                      <div className="col">
                        <div className="animated fadeIn">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={toggleEditClassModalHandler}
                          >
                            Edit
                      </button>
                        </div>
                      </div>
                    ) : null}
                    <div className="col">
                      <div className="animated fadeIn">
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={toggleCreateClassModalHandler}
                        >
                          Add
                          
                        </button>
                        {tutorials && tutorialsStep === 1 ?
                            <Tutorial skip={() => this.props.skipTutorialHandler(this.props.uid)} state={tutorialsStep} arrowAlignment="right" tutorialAlignment="rightAlignment" />
                            : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>

        {/* DESKTOP DISLPAY */}
        <div className="not-mobile">
          <nav className="secondary-navbar navbar animated fadeInDown">
            <div className="container-fluid">

              <div className="row">
                {/* Class Selection Dropdown */}
                {!this.props.loadClasses || this.props.initialize ? (
                  <Aux>
                    <div className="col align-self-center classes-loader-wrapper text-center">
                      <div className="loader dark classes-loader" /> Loading
                    </div>
                    <div className="col align-self-center">
                      <span />
                    </div>
                  </Aux>
                ) : (
                    <Aux>
                      {isClasses ? (
                        <Aux>
                          <div className="col align-self-center">
                            <form className="animated fadeIn">
                              <div className="form-group classes">
                                <select
                                  className="form-control"
                                  value={activeClass.name}
                                  onChange={event => {
                                    this.props.deselectHomeworkHandler();
                                    this.props.selectClassHandler(
                                      event.target.value, this.props.uid
                                    );
                                  }}
                                >
                                  {options}
                                </select>
                              </div>
                            </form>
                          </div>
                          <div className="col align-self-center">
                            <span />
                          </div>
                        </Aux>
                      ) : (
                          <Aux>
                            <div className="col align-self-center ">
                              <div className="classes-placeholder text-center">
                                No Classes
                        </div>
                            </div>
                            <div className="col align-self-center">
                              <span />
                            </div>
                          </Aux>
                        )}
                    </Aux>
                  )}
                {/* Create and Edit Class Buttons */}
                <div className="col align-self-center">
                <Button variant="primary" onClick={toggleCreateClassModalHandler}> Create Class</Button>
                  {tutorials && tutorialsStep === 1 ?
                            <Tutorial skip={() => this.props.skipTutorialHandler(this.props.uid, 1)} state={tutorialsStep} arrowAlignment="right" tutorialAlignment="rightAlignment" />
                            : null}
                </div>
                {isClasses ? (
                  <div className="col align-self-center animated fadeIn">
                  <Button variant="link" onClick={toggleEditClassModalHandler} >Edit Class</Button>
                  </div>
                ) : null}
              </div>
            </div>
          </nav>
        </div>
      </Aux >
    );
  }
}
const mapStateToProps = state => {
  return {
    loadClasses: state.classes.loadClasses,
    initialize: state.classes.initialize,
    uid: state.firebase.auth.uid,
    userData: state.firestore.data[state.firebase.auth.uid],
    students: state.students.students
  };
};

const mapDisptachToProps = dispatch => {
  return {
    loadClassesHandler: (uid) => dispatch(loadClasses(uid)),
    setTutorialsHandler: (uid) => dispatch(setTutorials(uid)),
    loadClassesCompleteHandler: () => dispatch(loadClassesComplete()),
    createClassHandler: (newClassName, uid) => dispatch(createClass(newClassName, uid)),
    selectClassHandler: (selectedClassName, uid) =>
      dispatch(selectClass(selectedClassName, uid)),
    saveClassHandler: (newClassName, students) =>
      dispatch(saveClass(newClassName, students)),
    deleteClassHandler: (activeClass, uid) => dispatch(deleteClass(activeClass, uid)),
    deselectHomeworkHandler: () => dispatch(deselectHomework()),
    skipTutorialHandler: (uid, step) => dispatch(skipTutorial(uid, step))
  };
};

export default withFirestore(
  connect(
    mapStateToProps,
    mapDisptachToProps
  )(Classes)
);
