import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Aux from "../../components/hoc/Aux";
import Logo from "../../assets/images/Logo.png";
import GoogleLogo from "../../assets/images/google.svg";
import {
  loginUser,
  loginUserWithGoogle,
  authErrorClear
} from "../../store/actions/authentication";

import "./login.css";

export class Login extends Component {
  state = {
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
    count: 0,
    tooManyAttempts: false
  };



  render() {

    const enterPressed = event => {
      var code = event.keyCode || event.which;
      if (code === 13) {
        checkForm();
        loginAttempt();
      }
    };


    // Valdation Check on Text Input
    const validation = (type, value) => {
      if (type === "email") {
        const email = value;
        // Is the email field empty?
        if (email.length === 0) {
          this.setState({
            emailError: "Email is required"
          });
        }
      }
      // Is the password field empty?
      if (type === "password") {
        if (value.length === 0) {
          this.setState({
            passwordError: "Password is required"
          });
        }
      }
    };

    // Two way binding for email
    const userNameChangeHandler = event => {
      this.props.authErrorClearHandler();
      this.setState({
        email: event.target.value,
        emailError: ""
      });
      validation("email", event.target.value);
    };

    // Two way binding for password
    const passworChangeHandler = event => {
      this.props.authErrorClearHandler();
      this.setState({
        password: event.target.value,
        passwordError: ""
      });
      validation("password", event.target.value);
    };

    // Validate form upon submission
    const checkForm = () => {
      const emailRegex = /\S+@\S+\.\S+/;
      const email = this.state.email;
      let isError = false;

      //Is the email in correct format?
      if (!emailRegex.test(this.state.email)) {
        this.setState({
          emailError: "Invalid Email"
        });
        isError = true;
      }
      // is the email field empty?
      if (email.length === 0) {
        this.setState({
          emailError: "Email is required"
        });
        isError = true;
      }
      //Is the password field empty?
      if (this.state.password.length === 0) {
        this.setState({
          passwordError: "password is required"
        });
        isError = true;
      }
      // If no error, try to log in the user
      if (!isError) {
        this.props.loginUserHandler(this.state.email, this.state.password);
      }
    };

    const loginAttempt = () => {
      let currentcount = this.state.count;
      if (currentcount < 4) {
        currentcount++;
        this.setState({
          count: currentcount
        });
      } else {
        this.setState({
          tooManyAttempts: true
        });
      }
    };

    // store empty variables for is-invalid class
    let emailIsInvalid = "";
    let passwordIsInvalid = "";
    if (this.state.emailError !== "") {
      emailIsInvalid = " is-invalid";
    }
    if (this.state.passwordError !== "") {
      passwordIsInvalid = " is-invalid";
    }

    if (this.props.isAuthenticated) {
      return <Redirect to="/app" />;
    }

    return (
      <Aux>
        <Link to="/register">
          <nav className="primary-navbar navbar">
            <div className="container-fluid">
              <div className="loginheader">
                <span className="line"> Don't have an account? </span>
                <span className="line">&nbsp; Sign up today</span>
              </div>
            </div>
          </nav>
        </Link>
        <div className="background" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6"
              onKeyPress={enterPressed}>
              <div className="card login">
                <div className="row justify-content-center">
                  <div className="col-11 col-sm-8">
                    <img alt="logo" className="logo" src={Logo} />
                    <h4>Sign In</h4>
                    <hr />

                    {/* Show error if there is too many log in attempts */}

                    {this.state.tooManyAttempts ? (
                      <div
                        className="alert alert-danger animated fadeIn"
                        role="alert"
                      >
                        Having trouble? Please contact &nbsp;
                        <a href="mailto:allie@elearningmedia.ca">
                          E&#8209;learning Media Inc.
                        </a>
                      </div>
                    ) : null}
                    <div className="form-group ">
                      <div className="input-group-prepend">
                        <i
                          className={
                            "material-icons float-left" + emailIsInvalid
                          }
                        >
                          email
                        </i>
                        <input
                          type="text"
                          className={
                            "form-control login-input" + emailIsInvalid
                          }
                          ref="email"
                          placeholder="Enter your email"
                          value={this.state.email}
                          onChange={userNameChangeHandler}
                          autoFocus
                        />
                      </div>
                    </div>
                    {this.state.emailError.length > 0 ? (
                      <label className="is-invalid animated fadeIn">
                        {this.state.emailError}
                      </label>
                    ) : null}
                    <div className="form-group bottom">
                      <div className="input-group-prepend">
                        <i
                          className={
                            "material-icons float-left" + passwordIsInvalid
                          }
                        >
                          lock
                        </i>
                        <input
                          type="password"
                          className={
                            "form-control login-input" + passwordIsInvalid
                          }
                          value={this.state.password}
                          onChange={passworChangeHandler}
                          placeholder="Password"
                          aria-label="Password"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      {this.state.passwordError.length > 0 ? (
                        <label className="is-invalid animated fadeIn">
                          {this.state.passwordError}
                        </label>
                      ) : null}
                    </div>



                    {this.props.loginError ? (
                      <div className="col text-center login-error animated fadeIn">
                        <div className="error">{this.props.loginError}</div>
                      </div>
                    ) : null}
                    <div
                      className="btn btn-primary col v-space"
                      onClick={() => {
                        checkForm();
                        loginAttempt();
                      }}

                    >
                      {this.props.authenticating ? (
                        <div className="btn-content-wrapper animated fadeIn">
                          <div className="loader" />
                          Signing In
                        </div>
                      ) : (
                          <div className="animated fadeIn ">Sign In</div>
                        )}
                    </div>



                    <div className="password-util row justify-content-end">
                      <div className="col text-left">
                        <div className="btn btn-link simple">
                          <Link to="/forgotpassword" className="forgotpassword">Forgot Password</Link>

                        </div>
                      </div>
                      {/* <div className="col">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox1"
                            value="option1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineCheckbox1"
                          >
                            Remember Me
                          </label>
                        </div>
                      </div> */}
                    </div>


                    <hr />
                    <div className="or text-center">
                      <h6>OR</h6>
                    </div>
                    <div
                      className="btn btn-danger google-signin col"
                      onClick={this.props.loginUserWithGoogleHandler}
                    >
                      <img alt="google logo" src={GoogleLogo} />
                      Google Sign In
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Aux >
    );
  }
}

const mapStateToProps = state => {
  return {
    loginError: state.authentication.loginError,
    authenticating: state.authentication.authenticating,
    isAuthenticated: state.firebase.auth.uid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loginUserHandler: (email, password) => dispatch(loginUser(email, password)),
    loginUserWithGoogleHandler: () => dispatch(loginUserWithGoogle()),
    authErrorClearHandler: () => dispatch(authErrorClear())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
