import React from "react";
import "./studentInput.css";

const StudentInput = props => {
  let fnameInvalid = "";
  let lnameInvalid = "";
  if (props.fnameError !== "") {
    fnameInvalid = " is-invalid";
  }
  if (props.lnameError !== "") {
    lnameInvalid = " is-invalid";
  }
  return (
    <div className="form-group" onKeyPress={props.enter}>
      <div className="row">
        <div className="col-5">
          <input
            data-index={props.index}
            data-name="fname"
            type="text"
            className={"form-control animated fadeIn" + fnameInvalid}
            id="firstName"
            placeholder="First name"
            value={props.fname}
            onChange={props.change}
            autoFocus
          />
          <div className="is-invalid">{props.fnameError}</div>
        </div>
        <div className="col-5">
          <input
            data-index={props.index}
            data-name="lname"
            type="text"
            className={"form-control animated fadeIn" + lnameInvalid}
            id="lastName"
            placeholder="Last name"
            value={props.lname}
            onChange={props.change}
          />
          <div className="is-invalid">{props.lnameError}</div>
        </div>
        <div className="col-2 actions">
          {props.students.length > 1 ? (
            <i
              id={props.index}
              className="material-icons delete animated fadeIn"
              onClick={props.deleteStudent}
            >
              delete
            </i>
          ) : null}
        </div>
      </div>
      <hr />
    </div>
  );
};

export default StudentInput;
