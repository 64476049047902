import React, { Component } from "react";
import { withFirestore } from "react-redux-firebase";
import { Redirect } from "react-router-dom";


import { connect } from "react-redux";
import Aux from "../components/hoc/Aux";
import "react-toastify/dist/ReactToastify.css";

// Containers
import PrimaryNav from "../components/primaryNav/primaryNav";
import Classes from "../containers/classes/classes";
import Students from "../containers/students/students";
import Homeworks from "../containers/homeworks/homeworks";

export class Assembly extends Component {

  render() {
    const logoutHandler = () => {
      this.props.firebase.logout();
    };

    if (!this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }

    return (
      <Aux>

        <PrimaryNav
          user={this.props.authenticatedUser}
          logout={logoutHandler}
          userProfileImage={this.props.userProfileImage}
        />
        <Classes />
        <div className="content-area">
          <div className="row">
            <div className="col-12 order-2 order-lg-1 col-lg-7 students">
              <Students />
            </div>
            <div className="col-12 order-1 order-lg-2 col-lg-5 ">
              <Homeworks />
            </div>
          </div>
        </div>
      </Aux>
    );
  }
}


const mapStateToProps = state => {
  return {
    userProfileImage: state.firebase.auth.photoURL,
    authenticatedUser: state.firebase.auth.email,
    isAuthenticated: state.firebase.auth.uid
  };
};

const mapDisptachToProps = dispatch => {
  return {};
};

export default withFirestore(
  connect(
    mapStateToProps,
    mapDisptachToProps
  )(Assembly)
);
